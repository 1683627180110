import DonationModal from "./DonationModal";
const Donation = () => {
    return (
        <>
            <DonationModal />

        </>


    );
}
export default Donation;
import axios from 'axios';
import Cookies from 'universal-cookie';
const apiUrl = process.env.REACT_APP_API_URL;



const cookies = new Cookies();

export const getTypeApi = async (type: string) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(
        `${apiUrl}/taxonomy/get?type=${type}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    };

  
    export const getTaxonomyApi = async (type: string) => {
      const token = cookies.get('authToken');
      try {
        const response = await axios.get(
          `${apiUrl}/donation/gettaxonomy?type=${type}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    };
import { getTypeApi } from "../../Api/Taxonomy";
import { addDonationTPApi } from "../../Api/DonationTypePurpose";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { redirect, useNavigate } from "react-router-dom";

type DonationTypePurpose = {
  id: string;
  Id: string;
  type: string;
  purpose: string;
  value: string;
  parentId: string | null;
};

export function DonationTypePurposeForm() {
  const [type, setDonationType] = useState<string | null>(null);
  const [purpose, setPurpose] = useState<string | null>(null);
  const [dataPurpose, setDataPurpose] = useState<DonationTypePurpose[]>([]);
  const [dataType, setDataType] = useState<DonationTypePurpose[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTypeApi("Donation Type");
        setDataType(response);
        const res = await getTypeApi("Purpose");
        setDataPurpose(res);
      } catch (error) {
        console.error("Error fetching Data:", error);
      }
    };
    fetchData();
  }, []);

  const filteredDonationType = dataType.filter(
    (item) => item.type === "Donation Type" && item.parentId === null
  );

  const filteredPurpose = dataPurpose.filter(
    (item) => item.type === "Purpose" && item.parentId === null
  );

  const handleSubmitClick = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!type || !purpose) {
      toast.success("Please fill all the fields");
      return;
    }

    try {
      await addDonationTPApi(type, purpose);
      toast.success("Added Successfully");
      redirect("/admin/DonationTypePurpose");
    } catch (error) {
      toast.error("Failed to process");
      console.error("Error during submission:", error);
    }

    handleResetForm();
  };

  const handleResetForm = () => {
    setDonationType(null);
    setPurpose(null);
    navigate(-1);
  };

  return (
    <div className="max-w-6xl p-6">
      <div className="text-center text-3xl font-bold text-blue-600 mb-5 mt-5">
        <p>Add New Donation Type Purpose</p>
      </div>
      <form onSubmit={handleSubmitClick} className="mt-8 space-y-4">
        <div className="flex flex-col space-y-2">
          <label className="font-medium text-black dark:text-gray-200">
            Donation Type
          </label>
          <select
            className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
            value={type || ""}
            required
            onChange={(e) => {
              setDonationType(e.target.value);
              setPurpose("");
            }}
          >
            <option value="" disabled>
              Select Donation Type
            </option>
            {filteredDonationType.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col space-y-2 ">
          <label className="font-medium text-black dark:text-gray-200">
            Donation Purpose
          </label>
          <select
            className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
            value={purpose || ""}
            required
            onChange={(e) => setPurpose(e.target.value)}
          >
            <option value="" disabled>
              Select Donation Purpose
            </option>
            {filteredPurpose.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-start mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-4"
          >
            Submit
          </button>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
            onClick={handleResetForm}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

import { getDonationTPApi, deleteDonationTPApi } from "../../Api/DonationTypePurpose";
import { getTypeApi } from "../../Api/Taxonomy"
import { IconArrowLeft, IconArrowRight, IconTrash } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../theme/ThemeProvider";

interface DonationTypePurposeInterface {
  Typeid: number;
  type: string;
  purpose: string;
}

interface TypePurpose {
  id: number;
  value: string;
}


const DonationTypePurpose = () => {

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const [search, setSearch] = useState('');

  const [donationTP, setDonationTP] = useState<DonationTypePurposeInterface[]>([]);
  const [typeData, setTypeData] = useState<TypePurpose[]>([]);
  const [purposeData, setPurposeData] = useState<TypePurpose[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const [donationTPResponse, TypeResponse, PurposeResponse] = await Promise.all([
          getDonationTPApi('donationTPId'),
          getTypeApi('Donation Type'),
          getTypeApi('Purpose')
        ]);

        setDonationTP(donationTPResponse);
        setTypeData(TypeResponse);
        setPurposeData(PurposeResponse);
      } catch (error) {
        toast.error('Failed to fetch data.');
      }
    }

    fetchData();
  }, []);

  const TypeValue = (typeId: string) => {
    const type = typeData.find(t => t.id.toString() === typeId);
    return type ? type.value : typeId;
  };

  const PurposeValue = (purposeId: string) => {
    const purpose = purposeData.find(p => p.id.toString() === purposeId);
    return purpose ? purpose.value : purposeId;
  }

  const handleAddClick = () => {
    navigate('/admin/DonationTypePurposeForm');
  };

  const handleDeleteClick = async ( type: string, purpose: string) => {
    try {
      await deleteDonationTPApi(type, purpose);
      setDonationTP((donationTP) => donationTP.filter((item: any) => item.type !== type && item.purpose !== purpose));
      toast.success('Deleted successfully!');
    } catch (error) {
      toast.error('Failed to delete.');
    }
  };

  const filteredDonationTP = Array.isArray(donationTP)
    ? donationTP.filter(
      (donation) =>
        (donation.type && donation.type.toLowerCase().includes(search)) ||
        (donation.purpose && donation.purpose.toString().includes(search))
    )
    : [];

  const currentExpenses = filteredDonationTP.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredDonationTP.length / ITEMS_PER_PAGE);

  return (
    <div className={`container h-full px-1 mx-auto  ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <h2 className="text-3xl font-bold text-blue-600 mt-6 mb-4">Donation Type Purpose</h2>
      <input
        type="text"
        placeholder="Search Donation Type Purpose"
        className={`w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"
          }`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />


      <div className="flex-grow ml-0 mt-4 w-full">
        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr >
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                    }`} >
                  Action
                </th>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                    }`} >
                  <span>Type</span>
                </th>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                    }`} >
                  <span>Purpose</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {currentExpenses.map((donationTP) => (
                <tr className={`${isDarkMode ? "bg-globalBg text-gray-300" : "bg-[#ffff] text-gray-500"}`} key={donationTP.type && donationTP.purpose}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex items-center gap-x-6">
                      <IconTrash
                        className="cursor-pointer text-gray-500"
                        onClick={() => handleDeleteClick(donationTP.type, donationTP.purpose)}
                      />
                    </div>
                  </td>
                  <td className={`px-4 py-4 text-sm font-medium ${isDarkMode ? " text-gray-500 " : " text-gray-500"
                  }`} >{TypeValue(donationTP.type)}</td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{PurposeValue(donationTP.purpose)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <button onClick={handleAddClick} className=" mt-3 flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
        <FaPlus className="mr-2 font-medium " />
        Add
      </button>

      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          <IconArrowLeft />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${currentPage === index + 1
                ? "bg-blue-600 text-white"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
              }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};

export default DonationTypePurpose
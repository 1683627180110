import { addBankApi, getBankApi, updateBankApi }  from  "../../Api/Bank";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export function BankDetailsForm() {
  const [BankName, setBankName] = useState('');
  const [BankBranch, setBankBranch] = useState('');
  const [IfscCode, setIfscCode] = useState('');
  const [AccNo, setAccNo] = useState('');
  const [Id, setId] = useState('');
  const [isEdit, setIsEditMode] = useState(false);

  const navigation = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    const handleApiClick = async () => {
      if (id) {
        const bankData = await getBankApi();
        const bankToEdit = bankData.find((bank: any) => bank.id === id);
        if (bankToEdit) {
          setId(bankToEdit.id);
          setBankName(bankToEdit.name);
          setBankBranch(bankToEdit.branch);
          setIfscCode(bankToEdit.ifscCode);
          setAccNo(bankToEdit.accountNumber);
          setIsEditMode(true);
        }
      }
    };
    handleApiClick();
  }, [id]);

  const handleSubmitClick = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!BankName || !BankBranch || !IfscCode || !AccNo) {
      alert('Please fill all the fields');
      return;
    }

    try {
      if (isEdit) {
        await updateBankApi(Id, BankName, BankBranch, IfscCode, AccNo);
        toast.success('Bank updated successfully');
      } else {
        await addBankApi(BankName, BankBranch, IfscCode, AccNo);
        toast.success('Bank Added Successfully');
      }
      navigation('/admin/BankDetails');
    } catch (error) {
      toast.error(isEdit ? 'Failed to update Bank' : 'Unable to add Bank');
    }

    setBankName('');
    setBankBranch('');
    setIfscCode('');
    setAccNo('');
  };

  const handleResetForm = () => {
    setBankName('');
    setBankBranch('');
    setIfscCode('');
    setAccNo('');                
    navigation(-1);
  };

  return (
    <div className="max-w-6xl p-6">
       <div className=" text-center text-3xl font-bold text-blue-600 mb-5 mt-5 justify-center">
          {isEdit ? 'Update Bank Details' : 'Add New Bank Details'}
      </div>
      <form onSubmit={handleSubmitClick} className="mt-8 space-y-4">
        <div className="flex flex-col gap-4">
          <div>
            <label htmlFor="bankName" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
              Bank Name
            </label>
            <input
              id="bankName"
              type="text"
              required
              placeholder="Bank Name"
              value={BankName}
              onChange={(event) => setBankName(event.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="bankBranch" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
              Branch
            </label>
            <input
              id="bankBranch"
              type="text"
              required
              placeholder="Branch Name"
              value={BankBranch}
              onChange={(event) => setBankBranch(event.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="ifscCode" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
              IFSC Code
            </label>
            <input
              id="ifscCode"
              type="text"
              required
              placeholder="IFSC0000XXX"
              value={IfscCode}
              onChange={(event) => setIfscCode(event.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="accNo" className="block text-sm font-medium text-black dark:text-gray-200 mb-2">
              Account Number
            </label>
            <input
              id="accNo"
              type="number"
              required
              placeholder="1234567890"
              value={AccNo}
              onChange={(event) => setAccNo(event.target.value)}
              className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex justify-start space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {isEdit ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            onClick={handleResetForm}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../theme/ThemeProvider";
import { useContext } from "react";

const MadrasaETajushShariah = () => {


    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;

    const data: any = {
        students: {
            Hifz: "15+ Hifz Student",
            Aalim: "15+ Aalim Students",
            muallim: "2+ Muallim(s)",
        },
        benefits: {
            Hifz: "Food + Stay",
            Aalim: "Food + Stay",
            muallim: "Salary + Food + Stay",
        },
        budget: {
            Hifz: "₹7,00,000+",
            Aalim: "₹7,00,000+",
            muallim: "₹5,00,000+",
        },
    };

    const rows = Object.keys(data.students).map((key, index) => (
        <tr
            key={key}
            className={`text-left text-gray-400 font-light ${isDarkMode
                ? index === 0 || index === 2
                    ? "bg-[#2e2e2e]"
                    : "bg-[#242424]"
                : "bg-white"
                }`}
        >
            <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border  border-[#424242]" : "border  border-[#E5E7EB]"}`}>
                {data.students[key]}
            </td>
            <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                {data.benefits[key]}
            </td>
            <td className={`px-4 py-2 text-right  ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                {data.budget[key]}
            </td>
        </tr>


    ));

    const total = (
        <tr className={`font-semibold text-blue-400  text-left ${isDarkMode ? "bg-globalBg   " : "bg-[#ffff] "}`}>
            <th colSpan={2} className={`px-4 py-2  ${isDarkMode ? " border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                Total Yearly Approx Expenses
            </th>
            <th className={`px-4 py-2  text-right ${isDarkMode ? " border border-[#424242]" : "border border-[#E5E7EB]"}`}>₹19,00,000+</th>
        </tr>
    );

    const ths = (
        <tr className="text-left">
            <th className={`px-4 py-2 text-lg border  ${isDarkMode ? " text-[#c9c9c9] border-[#424242] " : "text-black "}`}>Category</th>
            <th className={`px-4 py-2 text-lg border  ${isDarkMode ? "text-[#c9c9c9] border-[#424242] " : "text-black "}`}>Benefits</th>
            <th className={`px-4 py-2 text-lg border text-right ${isDarkMode ? "text-[#c9c9c9] border-[#424242] " : "text-black "}`}>Budget</th>
        </tr>
    );

    return (
        <div className={`${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
            <div className={`lg:mx-72 md:mx-8 mx-4 text-justify py-5 `}>
                <h1 className="text-2xl md:text-2xl font-bold text-center text-blue-500 mb-4">
                    Madrasa E TajushShariah
                </h1>
                <p className="text-[#868EA4] mb-4  text-sm md:text-base">
                    TajushShariah Foundation is formed with a mission -{" "}
                    <span className={`font-semibold ${isDarkMode ? "text-white " : "text-black"} `}>
                        A step towards{" "}
                        <span className="text-blue-500">digitalization</span> for Islamic
                        Learning
                    </span>
                </p>
                <p className="text-[#868EA4] mb-4 text-sm md:text-base">
                    Madrasa E TajushShariah is one of the main projects running with the
                    mission to provide education to the student of Telangana (especially
                    Hyderabad) under the spiritual guidance of Alahazrat Imam Ahmad Raza
                    Barelvi to create the force of local Ulema for the future.
                </p>
                <p className="text-[#868EA4] mb-8 text-sm md:text-base">
                    We also commit to provide modern education (English, Mathematics, and
                    Computer) so they can compete in the world of digitalization.
                </p>
                <h2 className="text-xl md:text-2xl font-bold text-center text-blue-500 mb-4">
                    Next Year Plan
                </h2>
                <div className="overflow-x-auto mb-8">
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>{ths}</thead>
                        <tbody>{rows}</tbody>
                        <tfoot>{total}</tfoot>
                    </table>
                </div>
                <div className="text-left">
                    <Link to="/donate">
                        <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 text-sm md:text-base">
                            Donate Now
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MadrasaETajushShariah;

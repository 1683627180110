import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getExpenseApi } from "../../Api/Expense";
import toast from 'react-hot-toast';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import { FaPlus } from 'react-icons/fa';
import { ThemeContext } from '../theme/ThemeProvider';

interface ExpenseInterface {
  typeId: number;
  Id: string;
  type: string;
  SubType: string;
  purpose: string;
  amount: number;
  desc: string;
}

const Expense = () => {

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const [search, setSearch] = useState("");

  const [expenses, setExpenses] = useState<ExpenseInterface[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchExpenses() {
      try {
        const expenseData = await getExpenseApi('expenseId');
        setExpenses(expenseData);
      } catch (error) {
        toast.error('Failed to fetch expense data.');
      }
    }
    fetchExpenses();
  }, []);

  const handleEditClick = (id: string) => {
    navigate('/admin/ExpenseForm', { state: { id } });
  };

  const handleAddClick = () => {
    navigate('/admin/ExpenseForm');
  };

  // const renderEditButton = (id: string) => (
  //   <IconPencil
  //     className="mr-2 cursor-pointer"
  //     onClick={() => {
  //       expenses.find((expense) => expense.Id === id);
  //       handleEditClick(id);
  //     }}
  //   />
  // );

  const filteredExpenses = expenses.filter(
    (expense) =>
      (expense.type && expense.type.toLowerCase().includes(search.toLowerCase())) ||
      (expense.purpose && expense.purpose.toLowerCase().includes(search.toLowerCase())) ||
      (expense.amount && expense.amount.toString().includes(search.toLowerCase())) ||
      (expense.desc && expense.desc.toLowerCase().includes(search.toLowerCase()))
  );

  const currentExpenses = filteredExpenses.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredExpenses.length / ITEMS_PER_PAGE);


  return (
    <div className={`container h-full px-1 mx-auto  ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <h2 className="text-3xl font-bold text-blue-600 mt-6 ">Expense Details</h2>
      <div className="flex-grow ml-0 w-full mt-10">
        <form className="w-full">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
              <svg
                className="w-4 h-4 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >

              </svg>
            </div>
            <input
        type="text"
        placeholder="Search Expenses"
        className={`w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${
          isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"
        }`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      /> 
        </div>
        </form>


      </div>
      <div className="flex-grow ml-0 mt-4 w-full ">
      <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"}`} >
                  Edit
                </th>

                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                  }`} >
                  <span>Sub-Type</span>
                </th>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                  }`}>
                  <span>Purpose</span>
                </th>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                  }`} >
                  <span>Amount</span>
                </th>
                <th
                  scope="col"
                  className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-300" : " text-gray-500"
                  }`} >
                  Description
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">

              {currentExpenses.map((expense: any) => (


                <tr className= {`${isDarkMode ? "bg-globalBg text-gray-300" : "bg-[#ffff] text-gray-500"}`} key={expense.id}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex items-center gap-x-6">
                      <button
                        onClick={() => handleEditClick(expense.id)}
                        className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className={`px-4 py-4 text-sm font-medium ${isDarkMode ? " text-gray-500 " : " text-gray-500"
                  }`} >
                    {expense.type}
                  </td>
                  <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-00" : " text-gray-500"
                  }`} >
                    {expense.purpose}
                  </td>
                  <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                    {expense.amount}
                  </td>
                  <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                    {expense.desc}
                  </td>
                </tr>


              ))}
            </tbody>
          </table>
        </div>

        <div className="flex-grow ml-0 mt-4 w-0">
          <button onClick={handleAddClick} className="flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
            <FaPlus className="mr-2 font-medium " />
            Expense
          </button>
        </div>

        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            <IconArrowLeft />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 ${currentPage === index + 1
                ? "bg-blue-600 text-white"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          >
            <IconArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Expense

import React from 'react'
import { ThemeContext } from "../theme/ThemeProvider";
import { useContext } from "react";

function DigitalSupport() {


  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;
  return (
    <div className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#ffff] text-black"}`}>DigitalSupport</div>
  )
}

export default DigitalSupport
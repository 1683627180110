import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

const cookies = new Cookies();
export const donateApi = async (
    amount: number,
    details: any[],
    token: string,
    transactionType: number,
    userId?: string | null,
    bankId?: string | null,
    donatedOn?: Date | null,
    referenceNumber?: string | null,
    boxId?: string | null,
  ) => {

    try {
      const response = await axios.post(
        `${apiUrl}/donation/create`,
        {
          amount,
          donationDetails: details,
          transactionType,
          userId: userId ?? null,
          bankId: bankId ?? null,
          donatedOn: donatedOn ?? null,
          referenceNumber: referenceNumber ?? null,
          boxId: boxId ?? null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  export const donateHistoryApi = async () => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(
        `${apiUrl}/donation/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error(error);
  
      return false;
    }
  };
  
  export const donateHistoryByUserIdApi = async () => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get( `${apiUrl}/donation/getbyuserid`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return false;
    }
  };
  
  export const getDonationByIdApi = async (Id: string) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(`${apiUrl}/donation/GetById`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
  
      return false;
    }
  };
  
  export const donationUpdateApi = async (
    Id: string,
    amount: number,
    details: any[],
    transactionType: number,
    userId?: string | null,
    bankId?: string | null,
    donatedOn?: Date | null,
    referenceNumber?: string | null,
    boxId?: string | null,
  ) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.post(
        `${apiUrl}/donation/update`,
        {
          Id,
          amount,
          donationDetails: details,
          transactionType,
          userId: userId ?? null,
          bankId: bankId ?? null,
          donatedOn: donatedOn ?? null,
          referenceNumber: referenceNumber ?? null,
          boxId: boxId ?? null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };



  export const getHMTLDonationById = async (id: String) => {
    const token = cookies.get('authToken')
    try {
      const response = await axios.get(`${apiUrl}/Donation/get-html`, {
        headers:{
          Authorization: `Bearer ${token}`,
        },
        params:{
          id,
        }
      });
      return response.data;
      } catch (error) {
        throw error;
    }
  };

  export const transactionStatusApi = async (Id: string) => {
    const token = cookies.get('authtoken');
    try {
      const response = await axios.get(`${apiUrl}/api/Transaction/${Id}`, {
        headers:{
          Authorization: `Bearer ${token}`,
        },
        params:{
          Id,
        }
      });
      return response.data;
      } catch (error) {
        throw error;
    }
  };

  export const getDonationTPApi = async (donationTP: string) => {
    const token = cookies.get('authToken')
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/donationtypepurpose/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response",response)
      return response.data;
    } catch (error) {
      return false;
    }
  };
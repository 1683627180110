import React, { useState, useEffect } from "react";
import { IconArrowBarToUp } from "@tabler/icons-react";

const Scrolltopbutton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="fixed bottom-4 right-4 z-50">
            <div
                onClick={scrollToTop}
                className={` items-center cursor-pointer bg-blue-600 text-white  py-2 rounded-[4px] shadow-lg hover:bg-blue-900 duration-300  ${isVisible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-10 pointer-events-none"
                    }`}
            >
                <IconArrowBarToUp className=" h-6 w-10" />
            </div>
        </div>
    );
};

export default Scrolltopbutton;


import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { Dots } from './Dots';
import { ThemeContext } from "../../theme/ThemeProvider";

const Welcome = () => {

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;


  return (
    <div className=" pt-16 pb-8 lg:pt-32 lg:pb-12 mx-auto">
      <Dots className={` z-0 hidden lg:block absolute left-0 top-24 ml-14  ${isDarkMode ? " text-[#3b3b3b]" : "text-gray-100"} `} />
      <Dots className={`z-0  hidden lg:block absolute left-20 top-24 ml-14 ${isDarkMode ? " text-[#3b3b3b]" : "text-gray-100"}`} />
      <Dots className={` z-0 hidden lg:block absolute left-0 top-[235px] ml-14  ${isDarkMode ? " text-[#3b3b3b]" : "text-gray-100"}`} />
      <Dots className={` z-0 hidden lg:block absolute right-0 top-56 mr-8  ${isDarkMode ? " text-[#3b3b3b]" : "text-gray-100"}`} />

      <div className="text-center  px-4 justify-start ">
        <h1 className={`text-2xl left-0 md:text-3xl lg:text-[41px] font-extrabold tracking-tight mb-4 justify-start ${isDarkMode
          ? "text-[#ffff]"
          : "text-black"
          }`}>
          A step towards{' '}
          <span className="text-blue-500 dark:text-blue-400">digitalization</span> for Islamic Learning
        </h1>

        <div className="max-w-xl md:mx-auto">
          <p className="text-base md:text-lg text-[#868E96] dark:text-gray-400">
            Spread Islamic education to every corner of the world and preserve their faith. Empower
            Ulema with financial benefits so they can easily support this cause.
            Provide Ulema and Madrasas with digital tools to increase productivity.
          </p>
        </div>


        <div className="mt-6 flex justify-center items-center gap-4  ">
          <Link to="/about" className="no-underline">
            <button className={`px-7 py-3 text-sm font-semibold md:text-lg rounded-md ${isDarkMode
              ? "text-[#ffff] border border-[#424242] bg-[#2e2e2e] hover:bg-[#383838]"
              : " border border-[#CED4DA]  hover:bg-gray-100"
              }`}>
              Know More
            </button>
          </Link>
          <Link to="/donate" className="no-underline">
            <button className={`px-7 py-3 text-sm font-semibold md:text-lg rounded-md bg-blue-500 ${isDarkMode
              ? "text-[#ffff] hover:bg-blue-600"
              : " border-gray-200 text-black  hover:text-[#ffff]"
              }`}>
              Support Us
            </button>
          </Link>
        </div>

      </div>
    </div>
  );
}



export default Welcome;

import { useEffect, useState, useContext, useMemo } from 'react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import {  getDonationTPApi } from '../../Api/DonationTypePurpose';
import { getTaxonomyApi} from "../../Api/Taxonomy"
import { transactionStatusApi} from "../../Api/Donation"
import { donateApi} from "../../Api/Donation"

import Cookies from 'universal-cookie';
import { ThemeContext } from "../theme/ThemeProvider";
import { useNavigate } from 'react-router-dom';

const DonationModal = () => {

    const cookies = useMemo(() => new Cookies(), []);
const token = cookies.get("authToken");

    // const { user } = token;
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;

    type Donation = {
        id: string | null;
        typeId: string;
        type: string;
        amount: number;
        purposeId: string;
        purpose: string;
        onBehalfof: string;
        onBehalfofName: string;
    };
    interface Option {
        id: string;
        value: string;
        label: string;
    }

    interface DonationTypePurpose {
        id: string;
        type: string;
        purpose: string;
        purposeid: string,
        label: string
    }
    const [donations, setDonations] = useState<Donation[]>([]);
    const [donationTypeOptions, setDonationTypeOptions] = useState<Option[]>([]);
    const [purposeOptions, setPurposeOptions] = useState<Option[]>([]);
    const [donationType, setDonationType] = useState<Option | null>(null) as any
    const [donationPurpose, setDonationPurpose] = useState<Option | null>(null) as any;
    const [donationAmount, setDonationAmount] = useState(0);
    const [onBehalfof, setOnBehalfof] = useState('Self');
    const [onBehalfofName, setOnBehalfofName] = useState('');
    const [donationTP, setDonationTP] = useState<DonationTypePurpose[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [modal, setmodal] = useState(false);



    useEffect(() => {
        const fetchAllTaxonomies = async () => {
            try {
                const [typeResponse, purposeResponse] = await Promise.all([
                    getTaxonomyApi('Donation Type'),
                    getTaxonomyApi('Purpose')
                ]);

                setDonationTypeOptions(typeResponse);
                setPurposeOptions(purposeResponse);
                setIsDataLoaded(true);
            } catch (error) {
                console.error('Error fetching taxonomies:', error);
                console.error('Failed to load donation types and purposes');
            }
        };

        fetchAllTaxonomies();
    }, []);

    useEffect(() => {
        async function fetchDonationTypePurpose() {
            try {
                const fetchDonation = await getDonationTPApi('donationTPId');
                setDonationTP(fetchDonation);
            } catch (error) {
                console.error('Failed to fetch Donation Type Purpose data.');
            }
        }

        fetchDonationTypePurpose();
    }, []);

    const donationOptions = donationTypeOptions.map((DonationType) => ({
        value: DonationType.value,
        id: DonationType.id,
        label: DonationType.value
    }));

    const donationPurposeOptions = purposeOptions.map((DonationPurpose) => ({
        value: DonationPurpose.value,
        id: DonationPurpose.id,
        label: DonationPurpose.value
    }));

    function handleResetForm() {
        setDonationType(null);
        setDonationAmount(0);
        setDonationPurpose(null);
        setOnBehalfof('Self');
        setOnBehalfofName('');
        setmodal(false)
    }
    function handleSubmitClick(e: React.FormEvent) {
        e.preventDefault();

        if (!donationAmount || donationAmount <= 0 || !donationPurpose || !donationType) {
            alert('Please fill all the fields');
            return;
        }

        if (onBehalfof === 'Others' && !onBehalfofName) {
            alert('Please enter name for Others');
            return;
        }

        const donationData = {
            id: editingIndex !== null ? donations[editingIndex].id : null,
            typeId: donationType.id,
            type: donationType.value,
            purposeId: donationPurpose.id,
            purpose: donationPurpose.value,
            amount: donationAmount,
            onBehalfof: onBehalfof,
            onBehalfofName: onBehalfof === 'Others' ? onBehalfofName : ''
        };

        if (editingIndex !== null) {
            const updatedDonations = [...donations];
            updatedDonations[editingIndex] = donationData;
            setDonations(updatedDonations);
            setEditingIndex(null);
            setmodal(false);
        } else {
            setDonations([...donations, donationData]);
        }

        handleResetForm();
        setmodal(false)
    }
    const handleEditClick = (index: number) => {
        const donationToEdit = donations[index];

        if (!isDataLoaded) {
            console.error('Please wait while data is loading...');
            return;
        }

        const matchedType = donationTypeOptions.find(
            option => option.id === donationToEdit.typeId
        );

        const matchedPurpose = purposeOptions.find(
            option => option.id === donationToEdit.purposeId
        );

        if (!matchedType || !matchedPurpose) {
            console.error('Could not find matching type or purpose', {
                typeId: donationToEdit.typeId,
                purposeId: donationToEdit.purposeId,
                availableTypes: donationTypeOptions,
                availablePurposes: purposeOptions
            });
            console.error('Error loading donation details');
            return;
        }

        const updates = () => {
            setEditingIndex(index);
            setmodal(true)
            setDonationType({
                value: matchedType.value,
                id: matchedType.id,
                label: matchedType.value
            });
            setDonationPurpose({
                value: matchedPurpose.value,
                id: matchedPurpose.id,
                label: matchedPurpose.value
            });
            setDonationAmount(donationToEdit.amount);

            setOnBehalfof(donationToEdit.onBehalfof);
            setOnBehalfofName(donationToEdit.onBehalfofName || '');
        };

        updates();
        setmodal(true)

    };
    const renderEditButton = (index: number) => (
        <IconPencil
            className={` ml-2 cursor-pointer ${isDarkMode ? " text-gray-300" : "text-black"}`}
            onClick={() => handleEditClick(index)}
        />
    );
    const donationEzpay = async () => {
        const details = donations.map((donation) => ({
            amount: donation.amount,
            type: donation.typeId,
            purpose: donation.purposeId,
            onBehalfOf: donation.onBehalfofName,

        }));

        const transactionType = 1;
        const response = await donateApi(totalAmount, details, token, transactionType);
        window.location.href = response.data[0];
    };


    const totalAmount = donations.reduce((sum, donation) => sum + Number(donation.amount || 0), 0);

    useEffect(() => {
        const checkTransactionStatus = async () => {
            try {

                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get('donationId');

                let localId = localStorage.getItem('donationId');
                let res = await transactionStatusApi(id as string);

                if (res === 'E000' && id !== localId) {

                    cookies.get('donations');
                    cookies.remove('donations');
                    localStorage.setItem('donationId', id as string);

                    const savedDonations = cookies.get('donations');
                    setDonations(savedDonations ? savedDonations : []);

                }
                else {
                    const savedDonations = cookies.get('donations');
                    setDonations(savedDonations ? savedDonations : []);
                }
            }
            catch (error) {
                const savedDonations = cookies.get('donations');
                setDonations(savedDonations ? savedDonations : []);
            }
        };
        checkTransactionStatus();
    }, [cookies]);
    // type PaymentData = any
    // const handlePayment = (paymentData: PaymentData) => {
    // };
    const navigate = useNavigate();
    const handleAddDonationClick = () => {
        if (!token) {
            navigate('/login');
        } else {
            setmodal(true)
        };

    }
    return (
        <div className={` min-h-[70vh] ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
            <div className="py-8 lg:mx-[320px] md:mx-0 ">
                <h2 className="text-center font-bold text-3xl text-blue-400 md:mb-3 ">Donation</h2>
                <p className={`px-2 ${isDarkMode ? "text-gray-500" : ""}`}>
                    You can make a change by supporting our mission through your <b className={`${isDarkMode ? "text-white" : "text-black"}`}>Zakaat, Fitraat, Sadaqaat</b> and
                    <b className={`${isDarkMode ? "text-white" : "text-black"}`}> Atiyat </b> in the world of digitalization.
                </p>
                {modal && (
                    <div className={`fixed inset-0 flex items-center justify-center  bg-opacity-50 ${isDarkMode ? "bg-gray-900" : "bg-gray-100"}`}
                        onClick={() => setmodal(false)}
                    >
                        <div className={`p-6 rounded-lg shadow-lg w-full max-w-md border ${isDarkMode ? "bg-[#2e2e2e] border-gray-700" : "bg-[#ffff]"}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="relative">
                                <div className="absolute -top-10 right-0">
                                    <button
                                        onClick={() => setmodal(false)}
                                        className={`text-3xl   ${isDarkMode ? "text-gray-200 hover:text-gray-300" : " text-gray-600  hover:text-gray-800"}`}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="text-center mb-8">
                                    <div className="flex justify-center mt-6">
                                        <img src="images/TFLogo.svg" alt="Logo" className="w-16" />
                                    </div>
                                    <span
                                        className="text-transparent bg-clip-text font-extrabold text-[18px] ml-2.5 bg-gradient-to-r from-[#FD6E6A] to-[#FFC600]"
                                    >
                                        TajushShariah Foundation
                                    </span>
                                </div>
                            </div>
                            <label className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"} `}>
                                Donation Type*
                                <select
                                    required
                                    className={`block w-full p-2 mt-1 border rounded  font-normal text-sm focus:outline-none ${isDarkMode
                                        ? "border-gray-600 bg-gray-800 text-white  "
                                        : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                                        }`}
                                    value={donationType?.value || ''}

                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const selectedType = donationOptions.find(
                                            (option) => option.value === value
                                        );
                                        if (selectedType) {
                                            setDonationType(selectedType);
                                            setDonationPurpose(null);
                                        }
                                    }}
                                >                                                                           
                                    <option value="" disabled selected >Select your option</option>
                                    {donationOptions.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </label>


                            <div>
                                <label className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"} `}>Donation Amount*</label>
                                <input
                                    type="text"
                                    className={`w-full p-2 border rounded-md text-sm font-normal focus:outline-none
                                     ${isDarkMode
                                            ? "border-gray-600 bg-gray-800 text-white  "
                                            : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                                        }`
                                    }
                                    value={donationAmount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const amounts = value.split(',').map((amount) => Number(amount.trim())).filter((amount) => !isNaN(amount));
                                        setDonationAmount(Number(amounts));
                                    }}
                                />
                            </div>
                            <label className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"} `}>
                                Donation Purpose*
                                <select
                                    required
                                    className={`block w-full p-2 mt-1 rounded font-normal text-sm focus:outline-none border ${isDarkMode
                                        ? "border-gray-600 bg-gray-800 text-white"
                                        : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                                        }`}
                                    value={donationPurpose?.value || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (donationType?.value === 'Normal / Sadaqah Nafilah') {
                                            const selectedPurpose = donationPurposeOptions.find(
                                                (p) => p.value === value
                                            );
                                            if (selectedPurpose) {
                                                setDonationPurpose({
                                                    value: selectedPurpose.value,
                                                    id: selectedPurpose.id,
                                                    label: selectedPurpose.value,
                                                });
                                            }
                                        } else {
                                            const selectedDTP = donationTP.filter((dtp) => dtp.type === donationType?.id).find((dtp) => {
                                                const taxonomyPurpose = donationPurposeOptions.find(
                                                    (p) => p.id === dtp.purpose
                                                );
                                                return taxonomyPurpose?.value === value;
                                            });

                                            if (selectedDTP) {
                                                const taxonomyPurpose = donationPurposeOptions.find(
                                                    (p) => p.id === selectedDTP.purpose
                                                );
                                                if (taxonomyPurpose) {
                                                    setDonationPurpose({
                                                        value: taxonomyPurpose.value,
                                                        id: selectedDTP.purpose,
                                                        label: taxonomyPurpose.value,
                                                    });
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <option value="" className="text-gray-400" disabled selected>
                                        Select Donation Purpose
                                    </option>
                                    {donationType?.value === 'Normal / Sadaqah Nafilah'
                                        ? donationPurposeOptions.map((option) => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))
                                        : donationTP.filter((dtp: any) => dtp.type === donationType?.id).map((donationtp: any) => {
                                            const taxonomyPurpose = donationPurposeOptions.find(
                                                (purpose) => purpose.id === donationtp.purpose
                                            );
                                            if (!taxonomyPurpose) {
                                                return undefined;
                                            }
                                            return {
                                                value: taxonomyPurpose.value,
                                                id: donationtp.purpose,
                                                label: taxonomyPurpose.value,
                                            };
                                        })
                                            .filter(
                                                (item: any): item is { value: string; id: string; label: string } =>
                                                    item !== undefined
                                            )
                                            .map((item: any) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                </select>
                            </label>
                            <label className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"} `}>
                                On Behalf of*
                                <select
                                    required
                                    className={`block w-full p-2 mt-1 border rounded font-normal text-sm focus:outline-none ${isDarkMode
                                        ? "border-gray-600 bg-gray-800 text-white"
                                        : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                                        }`}
                                    value={onBehalfof}
                                    onChange={(e) => setOnBehalfof(e.target.value)}
                                >
                                    <option value="Self">Self</option>
                                    <option value="Others">Others</option>
                                </select>
                            </label>

                            {onBehalfof === 'Others' && (
                                <label className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"} `}>
                                    Name*
                                    <input
                                        type="text"
                                        className={`block w-full p-2 mt-1 border rounded focus:outline-none font-normal text-sm ${isDarkMode
                                            ? "border-gray-600 bg-gray-800 text-white  "
                                            : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                                            }`}
                                        value={onBehalfofName}
                                        onChange={(e) => setOnBehalfofName(e.target.value)}
                                    />
                                </label>
                            )}
                            <button
                                className=" text-left mt-4 px-3 py-2 text-sm font-semibold bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={handleSubmitClick}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
                {donations.length !== 0 && (
                    <div className="mb-5 mt-6">
                        <div className="overflow-x-auto">
                            <table className="min-w-full table-auto border-collapse ">
                                <thead>
                                    <tr className={`border-b  text-left  ${isDarkMode ? " text-gray-300 border-gray-700" : "text-black border-gray-300"}`}>
                                        <th className="px-4 py-1 text-sm">Actions</th>
                                        <th className="px-4 py-2 text-sm">Donation Type</th>
                                        <th className="px-4 py-2 text-sm">Amount</th>
                                        <th className="px-4 py-2 text-sm">Purpose</th>
                                        <th className="px-4 py-2 text-sm">On Behalfof</th>
                                        <th className="px-4 py-2 text-sm">On Behalfof Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {donations.map((donation, index) => (
                                        <tr key={index} >
                                            <td className="px-4 py-2 flex flex-row">
                                                <button
                                                    className="mr-2 text-red-600 hover:text-red-800"
                                                    onClick={() => {
                                                        const newDonations = [...donations];
                                                        newDonations.splice(index, 1);
                                                        setDonations(newDonations);
                                                    }}
                                                >
                                                    <IconTrash />
                                                </button>
                                                {renderEditButton(index)}
                                            </td>
                                            <td className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>{donation.type}</td>
                                            <td className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>{donation.amount}</td>
                                            <td className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>{donation.purpose}</td>
                                            <td className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>{donation.onBehalfof}</td>
                                            <td className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>{donation.onBehalfofName}</td>
                                        </tr>
                                    ))}
                                    <tr className={`${isDarkMode ? " text-gray-300 border-t border-gray-700" : "text-black border-t border-gray-300"}`}>
                                        <td className="px-4 py-2"></td>
                                        <td className="px-4 py-2 text-sm">Total</td>
                                        <td className="px-4 py-2 text-sm">{totalAmount}</td>
                                        <td className="px-4 py-2"></td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                <div className="flex flex-wrap items-center gap-4 mt-8 px-2">
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm font-semibold "

                        onClick={handleAddDonationClick}
                    >
                        Add {donations.length > 0 ? 'More' : 'Donation'}
                    </button>
                    {donations.length > 0 && (
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded text-sm font-semibold  hover:bg-blue-600"
                            onClick={donationEzpay}
                        >
                            Donate Now
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
export default DonationModal;

import React, { useState, useEffect, useContext } from 'react';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { getAllBalanceTransfer } from "../../Api/BalanceTransfer";
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa';
import { ThemeContext } from '../theme/ThemeProvider';
import { getUserByRoleType } from '../../Api/User';
import { getAllBankApi } from '../../Api/Bank';

interface BalanceTransferInterface {
  typeId: number;
  // id: string;
  transferFrom: string;
  transferTo: string;
  transferAccountFrom: string;
  transferAccountTo: string;
  amount: number;
  desc: string;
}

interface UserBank {
  id: string,
  label: string
  name: string,
  accountNumber: number
}

const BalanceTransfer = () => {

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;


  const [allusersdata, setUserdetaildata] = useState<UserBank[]>([]);
  const [allbankdata, setBankdetaildata] = useState<UserBank[]>([]);
  const [search, setSearch] = useState("");
  const [BalanceTransfer, setBalanceTransfer] = useState<BalanceTransferInterface[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const [balanceTransferData, userData, bankData] = await Promise.all([
          getAllBalanceTransfer("banktransferId"),
          getUserByRoleType(2),
          getAllBankApi(),
        ]);

        setBalanceTransfer(balanceTransferData);
        setUserdetaildata(
          userData.map((user: any) => ({
            id: user.id,
            name: user.name,
            label: user.name,
          }))
        );
        setBankdetaildata(
          bankData.map((bank: any) => ({
            id: bank.id,
            name: bank.name,
            accountNumber: bank.accountNumber,
            label: `${bank.name} - (${bank.accountNumber})`,
          }))
        );
      } catch (error) {
        toast.error("Failed to fetch data.");
      }
    }

    fetchData();
  }, []);



  const TransferAccountValue = (typeId: string, isBank: boolean) => {
    if (!typeId) return "N/A";
  
    const data = isBank ? allbankdata : allusersdata;
    const transfer = data.find((t) => t.id.toString() === typeId);
    console.log("data", data)
  
    if (transfer) {
      return isBank
        ? `${transfer.name} - ${transfer.accountNumber}`: transfer.name;
    }
  };
  
  const handleEditClick = (id: string) => {
    navigate('/admin/BalanceTransferForm', { state: { id } });
  };

  const handleAddClick = () => {
    navigate('/admin/BalanceTransferForm');
  };

  const filteredBalanceTransfer = BalanceTransfer.filter(
    (balanceTransfer) =>
      balanceTransfer.transferAccountFrom.toLowerCase().includes(search.toLowerCase()) ||
      balanceTransfer.transferAccountTo.toLowerCase().includes(search.toLowerCase()) ||
      balanceTransfer.amount.toString().includes(search) ||
      balanceTransfer.desc.toLowerCase().includes(search.toLowerCase())
  );
  
  const currentBalanceTransfer = filteredBalanceTransfer.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredBalanceTransfer.length / ITEMS_PER_PAGE);

  return (

    <div className={`container h-full px-1 mx-auto  ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <h2 className="text-3xl font-bold text-blue-600 mb-5 mt-5">Balance Transfer Details</h2>
      <input
        type="text"
        placeholder="Search Balance Transfer"
        className={`w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"
          }`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />


      <div className="flex-grow ml-0 mt-4 w-full">
        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  Edit
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>From</span>
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>To</span>
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {currentBalanceTransfer.map((balanceTransfer: any) => (
                <tr key={balanceTransfer.id}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex items-center gap-x-6">
                      <button
                        onClick={() => handleEditClick(balanceTransfer.id)}
                        className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className={`px-4 py-4 text-sm font-medium ${isDarkMode ? " text-gray-500 " : " text-gray-500"}`} >
                    {TransferAccountValue(balanceTransfer.transferAccountFrom, balanceTransfer.transferFrom === "Bank")}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {TransferAccountValue(balanceTransfer.transferAccountTo, balanceTransfer.transferTo === "Bank")}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {balanceTransfer.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button onClick={handleAddClick} className="mb-5 mt-3 flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
          <FaPlus className="mr-2 font-medium " />
          Add
        </button>

        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            <IconArrowLeft />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 ${currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700 hover:bg-gray-400"}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          >
            <IconArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BalanceTransfer

import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "@11solutionspvtltd/eleven-solutions-common-website/dist/index"; // Existing user slice
import userProfileReducer from "./Slices/userProfileSlice"; // Import the new slice reducer

const store = configureStore({
  reducer: {
    user: userSlice, // Existing user reducer
    userProfile: userProfileReducer, // New user profile reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import {
  addBalanceTransfer,
  updateBalanceTransfer,
  getBalanceTransferById,
} from "../../Api/BalanceTransfer";
import { getAllBankApi} from "../../Api/Bank"
import { getUserByRoleType} from "../../Api/User"

type Option = {
  id: number;
  value: string;
  label: string;
};

type BankData = {
  id: string;
  label: string;
  value: string;
};

type AdminData = {
  id: string;
  label: string;
  value: string;
};

const TransferModes: Option[] = [
  { id: 1, value: "Bank", label: "Bank" },
  { id: 2, value: "User", label: "User" },
];

const BalanceTransferForm = () => {
  const [bankData, setBankData] = useState<BankData[]>([]);
  const [adminData, setAdminData] = useState<AdminData[]>([]);
  const [amount, setAmount] = useState<number>(0);
  const [desc, setDesc] = useState<string>("");
  const [isEdit, setIsEdit] = useState(false);
  const [transferFrom, setTransferFrom] = useState<Option | null>(null);
  const [transferTo, setTransferTo] = useState<Option | null>(null);
  const [transferAccountFrom, setTransferAccountFrom] = useState<string | null>(null);
  const [transferAccountTo, setTransferAccountTo] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const fetchBanks = useCallback(async () => {
    try {
      const response = await getAllBankApi();
      const formattedBanks = response.map((bank: any) => ({
        value: bank.id,
        label: `${bank.name} - ${bank.accountNumber}`,
      }));
      setBankData(formattedBanks);
    } catch (error) {
      toast.error("Error fetching bank data.");
    }
  }, []);

  const fetchAdmins = useCallback(async () => {
    try {
      const response = await getUserByRoleType(2);
      const formattedAdmins = response.map((admin: any) => ({
        value: admin.id,
        label: admin.name,
      }));
      setAdminData(formattedAdmins);
    } catch (error) {
      toast.error("Error fetching admin data.");
    }
  }, []);

  const fetchBalanceTransfer = useCallback(async () => {
    if (id) {
      try {
        const response = await getBalanceTransferById(id);
        if (response) {
          const transferFromMode = TransferModes.find(
            (mode) => mode.value === response.transferFrom
          );
          const transferToMode = TransferModes.find(
            (mode) => mode.value === response.transferTo
          );
  
          setTransferFrom(transferFromMode || null);
          setTransferTo(transferToMode || null);
  
          const fromAccountId =
            transferFromMode?.value === "Bank"
              ? response.transferAccountFrom
              : response.transferAccountFrom;
  
          const toAccountId =
            transferToMode?.value === "Bank"
              ? response.transferAccountTo
              : response.transferAccountTo;
  
          setTransferAccountFrom(fromAccountId || null);
          setTransferAccountTo(toAccountId || null);
          setAmount(response.amount);
          setDesc(response.desc);
          setIsEdit(true);
        }
      } catch (error) {
        toast.error("Error fetching balance transfer data.");
      }
    }
  }, [id]);
  

  useEffect(() => {
    fetchBanks();
    fetchAdmins();
  }, [fetchBanks, fetchAdmins]);

  useEffect(() => {
    fetchBalanceTransfer();
  }, [fetchBalanceTransfer]);

  const handleSubmit = async (event: React.FormEvent) => {  
    event.preventDefault();
    if (!transferAccountFrom || !transferFrom || !transferTo || !transferAccountTo || amount <= 0 || !desc) {
      toast.error("Please fill all fields");
      return;
    }

    try {
      if (isEdit) {
        await updateBalanceTransfer(
          id,
          transferAccountFrom,
          transferFrom.value,
          transferTo.value,
          transferAccountTo,
          amount,
          desc
        );
        toast.success("Balance Transfer updated successfully.");
      } else {
        await addBalanceTransfer(
          transferAccountFrom,
          transferFrom.value,
          transferTo.value,
          transferAccountTo,
          amount,
          desc
        );
        toast.success("Balance Transfer added successfully.");
      }
      navigate("/admin/BalanceTransfer");
    } catch (error) {
      toast.error("Failed to process balance transfer.");
    }
  };

  const handleResetForm = () => {
    setTransferAccountFrom(null);
    setTransferFrom(null);
    setTransferTo(null);
    setTransferAccountTo(null);
    setAmount(0);
    setDesc("");
    navigate(-1);
  };

  return (
    <div className="max-w-6xl p-6">
      <h2 className="text-3xl font-bold text-blue-600 text-center mb-5 mt-5">
        {isEdit ? "Update Balance Transfer" : "Add New Balance Transfer"}
      </h2>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label className="font-medium text-black">Transfer From</label>
          <select
            className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            value={transferFrom?.value || ""}
            onChange={(e) => {
              const mode = TransferModes.find((option) => option.value === e.target.value) || null;
              setTransferFrom(mode);
              setTransferAccountFrom(null);
            }}
            required
          >
            <option value="" disabled>
              Select From
            </option>
            {TransferModes.map((mode) => (
              <option key={mode.id} value={mode.value}>
                {mode.label}
              </option>
            ))}
          </select>
        </div>

        {transferFrom && (
          <div>
            <label className="font-medium text-black">
              {transferFrom.value === "Bank" ? "Select Bank" : "Select Person"}
            </label>
            <select
              className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
              value={transferAccountFrom || ""}
              onChange={(e) => setTransferAccountFrom(e.target.value)}
              required
            >
              <option value="" disabled>
                {transferFrom.value === "Bank" ? "Choose Bank" : "Select Person"}
              </option>
              {(transferFrom.value === "Bank" ? bankData : adminData).map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label className="font-medium text-black">Transfer To</label>
          <select
            className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            value={transferTo?.value || ""}
            onChange={(e) => {
              const mode = TransferModes.find((option) => option.value === e.target.value) || null;
              setTransferTo(mode);
              setTransferAccountTo(null);
            }}
            required
          >
            <option value="" disabled>
              Transfer To
            </option>
            {TransferModes.map((mode) => (
              <option key={mode.id} value={mode.value}>
                {mode.label}
              </option>
            ))}
          </select>
        </div>

        {transferTo && (
          <div>
            <label className="font-medium text-black">
              {transferTo.value === "Bank" ? "Select Bank" : "Select Person"}
            </label>
            <select
              className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
              value={transferAccountTo || ""}
              onChange={(e) => setTransferAccountTo(e.target.value)}
              required
            >
              <option value="" disabled>
                {transferTo.value === "Bank" ? "Choose Bank" : "Select Person"}
              </option>
              {(transferTo.value === "Bank" ? bankData : adminData).map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label className="font-medium text-black">Amount</label>
          <input
            type="number"
            className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            required
          />
        </div>

        <div>
          <label className="font-medium text-black">Description</label>
          <textarea
            className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            required
          />
        </div>

        <div className="flex justify-start space-x-4 mt-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
          >
            {isEdit ? "Update" : "Submit"}
          </button>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            onClick={handleResetForm}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default BalanceTransferForm;

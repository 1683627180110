import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;



const cookies = new Cookies();
export const getUserApi = async (searchTerm: string) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(
        `${apiUrl}/login/GetAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          searchTerm: searchTerm,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return false;
    }
  };


  export const getUserByRoleType = async (roleType: number) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(`${apiUrl}/user/GetUserByRoleType?roleType=${roleType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
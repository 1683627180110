
import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  Header,
  Login,
  Users,
  UserForm,
  Taxionomies,
  TaxonomyForm,
  Template,
  TemplateFrom,
  Footer,
  Privacy,
  Terms,
  getProfileApi,
} from "@11solutionspvtltd/eleven-solutions-common-website";

import Home from "../Components/pages/homepages/Home";
import About from "../Components/about/About";
import HeaderProjects from "../Components/projects/Projects";
import Donation from "../Components/donation/Donation";
import DonationHistory from "../Components/donation/DonationHistory";
import MadrasaETajushShariah from "../Components/projects/MadrasaETajushShariah";
import MadrasaETajushShariahLand from "../Components/projects/MadrasaETajushShariahLand";
import MasjidETajushShariah from "../Components/projects/MasjidETajushShariah";
import DigitalSupport from "../Components/projects/DigitalSupport";
import Contact from "../Components/contacts/Contact";
import AdminSideBar from "../Components/admin/AdminSideBar";
import { setUserProfile } from "../Components/hooks/Redux/Slices/userProfileSlice";
import Expense from "../Components/admin/Expense";
import { ThemeContext } from "../Components/theme/ThemeProvider";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../Components/hooks/Redux/Redux";
import Cookies from "universal-cookie";
import { ExpenseForm } from "../../src/Components/admin/ExpenseForm";
import BalanceTransfer from "../../src/Components/admin/BalanceTransfer";
import BalanceTransferForm from "../../src/Components/admin/BalanceTransferForm";
import { BankDetailsForm } from "../../src/Components/admin/BankDetailsForm";
import BankDetails from "../../src/Components/admin/BankDetails";
import DonationTypePurpose from "../../src/Components/admin/DonationTypePurpose";
import { DonationTypePurposeForm } from "../../src/Components/admin/DonationTypePurposeForm";
import { DonationDetails } from "../Components/admin/DonationDetails";
import { DonationDetailForm } from "../Components/admin/DonationDetailForm";
import Box from "../Components/admin/Box";
import BoxForm from "../Components/admin/BoxForm";

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID as string;

function AppRouter() {
  const location = useLocation();
  const userProfile = useAppSelector(
    (state: any) => state.userProfile.userData
  );
  const [userType] = useState(null);
  const [isLoading] = useState(false);
  const isAdminRoute = location.pathname.startsWith("/admin");
  const [iscloseModal, setclosemodal] = useState(true);
  const themeContext = useContext(ThemeContext);
  const isDarkMode = themeContext?.isDarkMode ?? false;
  const toggleTheme = themeContext?.toggleTheme ?? (() => { });
  const cookies = new Cookies();
  const [userLogedin, setUserLogedin] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const token = cookies.get("authToken");
  const [ModalOpen] = useState(true);
  const url: string = process.env.REACT_APP_API_URL!;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const closeModal = () => {
    setclosemodal(!iscloseModal);
    navigate("/home");
  };

  useEffect(() => {
    if (token) {
      setUserLogedin(true);
      const fetchProfile = async () => {
        try {
          const actualToken =
            typeof token === "object" && token.data ? token.data.token : token;

          const response = await getProfileApi(actualToken, url);
          setUserDetails(response);
          if (response) {
            dispatch(setUserProfile(response));
          }
        } catch (error) { }
      };

      fetchProfile();
    }
  }, [dispatch, token, url]);

  useEffect(() => {
    if (location.pathname === "/login") {
      if (userProfile) {
        setclosemodal(false);
      } else {
        setclosemodal(true);
      }
    }
  }, [location.pathname, userProfile]);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }

  const menugroups = [
    {
      label: "Projects",
      path: "/projects",
      onClick: () => navigate("/projects"),
    },
    {
      label: "Donate",
      path: "/donate",
      onClick: () => navigate("/donate"),
    },
  ];
  const donationHistory = {
    label: "Donation History",
    onClick: () => navigate("/donation-history"),
  };

  return (
    <>
      {!isAdminRoute && (
        <Header
          toggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
          userLogedin={userLogedin}
          userType={userType}
          url={url}
          img={"images/TFLogo.svg"}
          name="TajushShariah Foundation"
          userdetails={userDetails}
          menugroups={menugroups}
          donationHistory={donationHistory}
        />
      )}
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<HeaderProjects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donation />} />
        <Route path="/donation-history" element={<DonationHistory />} />
        <Route
          path="/login"
          element={
            iscloseModal ? (
              <Login
                closeModal={closeModal}
                isDarkMode={isDarkMode}
                url={url}
                ModalOpen={ModalOpen}
                img={"images/TFLogo.svg"}
              />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/contact" element={<Contact />} />

        <Route
          path="/MadrasaETajushShariah"
          element={<MadrasaETajushShariah />}
        />
        <Route
          path="MadrasaETajushShariahLand"
          element={<MadrasaETajushShariahLand />}
        />
        <Route
          path="/MasjidETajushShariah"
          element={<MasjidETajushShariah />}
        />
        <Route path="/DigitalSupport" element={<DigitalSupport />} />
        <Route
          path="/termsandconditions"
          element={<Terms isDarkMode={isDarkMode} />}
        />
        <Route
          path="/privacyandpolicy"
          element={<Privacy isDarkMode={isDarkMode} />}
        />
        {userProfile?.roleType === 2 ? (
          <Route path="/admin" element={<AdminSideBar />}>
            <Route path="Expense" element={<Expense />} />
            <Route path="ExpenseForm" element={<ExpenseForm />} />
            <Route path="users" element={<Users url={url} />} />
            <Route path="users/userform" element={<UserForm url={url} />} />
            <Route path="taxinomies" element={<Taxionomies url={url} />} />
            <Route
              path="taxinomies/taxonomyform"
              element={<TaxonomyForm url={url} />}
            />
            <Route path="template" element={<Template url={url} />} />
            <Route
              path="template/templateform"
              element={<TemplateFrom url={url} />}
            />
            <Route path="box" element={<Box />} />
            <Route path="box/boxform" element={<BoxForm />} />
            <Route path="BalanceTransfer" element={<BalanceTransfer />} />
            <Route
              path="BalanceTransferForm"
              element={<BalanceTransferForm />}
            />
            <Route path="BankDetails" element={<BankDetails />} />
            <Route path="BankDetailsForm" element={<BankDetailsForm />} />
            <Route
              path="DonationTypePurpose"
              element={<DonationTypePurpose />}
            />
            <Route
              path="DonationTypePurposeForm"
              element={<DonationTypePurposeForm />}
            />
            <Route path="DonationDetails" element={<DonationDetails />} />
            <Route path="DonationDetailForm" element={<DonationDetailForm />} />
          </Route>
        ) : null}
      </Routes>
      {!isAdminRoute && (
        <Footer
          isDarkMode={isDarkMode}
          companyName={"Eleven Software Solutions Pvt.Ltd"}
          name="TajushShariah Foundation"
          img="images/TFLogo.svg"
        />
      )}
    </>
  );
}
function Router() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <AppRouter />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default Router;

import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getAllBankApi } from "../../Api/Bank";
import { getTaxonomyApi } from "../../Api/Taxonomy";
import { getUserApi } from "../../Api/User";
import { fetchBoxByTaxonomyIdApi } from "../../Api/Box";
import { fetchUserByIdApi } from "../../Api/Box";
import {
  donateApi,
  donationUpdateApi,
  getDonationByIdApi,
} from "../../Api/Donation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { getDonationTPApi } from "../../Api/DonationTypePurpose";

type Donation = {
  id: string | null;
  typeId: string;
  type: string;
  amount: number;
  purposeId: string;
  purpose: string;
  onBehalfof: string;
  onBehalfofName: string;
};

interface Option {
  id: string;
  value: string;
  label: string;
}

type DonationBox = {
  type: string;
  id: string;
  value: string;
  parentId: string;
  label: string;
};

export const DonationDetailForm = () => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  const location = useLocation();
  const { id } = location.state || {};

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerOpen = () => setIsOpen(true);
  const handleDrawerClose = () => setIsOpen(false);

  const accountType = useMemo(
    () => [
      { id: 1, value: "Ezpay" },
      { id: 2, value: "Bank" },
      { id: 3, value: "Cash" },
      { id: 4, value: "Box" },
    ],
    []
  );

  interface DonationTypePurposeInterface {
    id: string;
    type: string;
    purpose: string;
    purposeid: string;
    label: string;
  }

  const navigate = useNavigate();

  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [datevalue, setDateValue] = useState<Date | null>(null);
  const [referenceNo, setReferenceNo] = useState<string>("");
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [donations, setDonations] = useState<Donation[]>([]);
  const [onBehalfof, setOnBehalfof] = useState<string>("Self");
  const [onBehalfofName, setOnBehalfofName] = useState<string>("");
  const [totalDonationAmount, setTotalDonaionAmount] = useState<number>();
  const [donationTypeOptions, setDonationTypeOptions] = useState<Option[]>([]);
  const [purposeOptions, setPurposeOptions] = useState<Option[]>([]);
  const [donationPurpose, setDonationPurpose] = useState<Option | null>(
    null
  ) as any;
  const [donationType, setDonationType] = useState<Option | null>(null) as any;
  const [donationTP, setDonationTP] = useState<DonationTypePurposeInterface[]>(
    []
  );
  const [box, setBox] = useState<DonationBox[]>([]);

  useEffect(() => {
    const handleGetApi = async () => {
      if (id) {
        try {
          const response = await getDonationByIdApi(id);
          if (response.transactionType === 2) {
            setSelectedBankId(response?.bankTransaction?.bankId || null);
            setReferenceNo(response?.bankTransaction?.referenceNumber || "");
            const transactionDate = new Date(response.donatedOn);
            setDateValue(transactionDate);
          } else {
            if (response.transactionType === 3) {
              setSelectedBankId(null);
              setReferenceNo("");
              const transactionDate = new Date(response.donatedOn);
              setDateValue(transactionDate);
            } else {
              setSelectedBankId(null);
              setReferenceNo("");
              const transactionDate = new Date(response.donatedOn);
              setDateValue(transactionDate);
            }
            if (response.transactionType === 4) {
              setSelectedBox(response?.boxTransaction?.boxId || null);
            }
          }
          const selectAccountType =
            accountType.find((type) => response.transactionType === type.id)
              ?.value || "Other";
          setSelectedAccountType(selectAccountType);
          setSelectedUserId(response.userId);
          setSearchUserName(response.userName);
          setTotalDonaionAmount(response.amount);

          const donations = response?.donationDetails.map((details: any) => ({
            id: details.id,
            typeId: details.type,
            type: details.donationType,
            amount: details.amount,
            purposeId: details.purpose,
            purpose: details.donationPurpose,
            onBehalfof: details.onBehalfOf ? "Others" : "Self",
            onBehalfofName: details.onBehalfOf
              ? details.onBehalfOf
              : "--------",
          }));
          setIsEditDetails(true);
          setDonations(donations);
        } catch (error) {
          toast.error("Error fetching donation details: ");
        }
      }
    };

    handleGetApi();
  }, [id, accountType]);
  // }, [id]);

  function handleResetForm() {
    setDonationType(null);
    setDonationAmount(0);
    setDonationPurpose(null);
    setOnBehalfof("");
    setOnBehalfofName("");
  }

  function handleSubmitClick(e: React.FormEvent) {
    e.preventDefault();

    if (
      !donationAmount ||
      donationAmount <= 0 ||
      !donationPurpose ||
      !donationType
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (onBehalfof === "Others" && !onBehalfofName) {
      alert("Please enter name for Others");
      return;
    }

    const donationData = {
      id: editingIndex !== null ? donations[editingIndex].id : null,
      typeId: donationType.id,
      type: donationType.value,
      purposeId: donationPurpose.id,
      purpose: donationPurpose.value,
      amount: donationAmount,
      onBehalfof: onBehalfof,
      onBehalfofName: onBehalfof === "Others" ? onBehalfofName : "",
    };

    if (editingIndex !== null) {
      const updatedDonations = [...donations];
      updatedDonations[editingIndex] = donationData;
      setDonations(updatedDonations);
      setEditingIndex(null);
      setIsEditModal(false);
    } else {
      setDonations([...donations, donationData]);
    }

    handleResetForm();
    handleDrawerClose();
  }
  const handleEditClick = (index: number) => {
    const donation = donations[index];
    setDonationType({
      value: donation.type,
      id: donation.typeId,
      label: donation.type,
    });
    setDonationAmount(donation.amount);
    setDonationPurpose({
      value: donation.purpose,
      id: donation.purposeId,
      label: donation.purpose,
    });
    setOnBehalfof(donation.onBehalfof);
    setOnBehalfofName(donation.onBehalfofName);
    setEditingIndex(index);
    setIsEditModal(true);
  };

  const handleEditClose = () => {
    setEditingIndex(null);
    setDonationType(null);
    setDonationAmount(0);
    setDonationPurpose(null);
    setOnBehalfof("");
    setOnBehalfofName("");
    setIsEditModal(false);
  };

  const [banks, setBanks] = useState([]);
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchUserResults, setSearchUserResults] = useState<any[]>([]);
  const [selectedBankId, setSelectedBankId] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedAccountType, setSelectedAccountType] = useState<string>("");
  const [selectedbox, setSelectedBox] = useState<string | null>(null);

  const handleDonationButton = async () => {
    if (selectedAccountType === "Bank") {
      if (!selectedBankId || !selectedUserId || !datevalue || !referenceNo) {
        alert("Please fill all the fields");
        return;
      }
    } else if (selectedAccountType === "Cash") {
      if (!selectedUserId || !datevalue) {
        alert("Please fill all the fields");
        return;
      }
    }

    const transactionType =
      accountType.find(
        (accType) =>
          selectedAccountType.trim().toLowerCase() ===
          accType.value.trim().toLowerCase()
      )?.id || 0;

    if (isEditDetails === true) {
      const details = donations.map((donation) => ({
        id: donation.id && isValidGuid(donation.id) ? donation.id : undefined, // Check if the id is valid GUID
        amount: donation.amount,
        type: donation.typeId,
        purpose: donation.purposeId,
        onBehalfOf: donation?.onBehalfof,
        OnBehalfofName: donation?.onBehalfofName,
      }));

      if (
        selectedAccountType === "EzPay" &&
        totalAmount !== totalDonationAmount
      ) {
        toast.error(
          `Total donation amount cannot be changed for EzPay. It must equal to ${totalDonationAmount}.`
        );
        return;
      }

      try {
        await donationUpdateApi(
          id,
          totalAmount,
          details,
          transactionType,
          selectedUserId,
          selectedBankId,
          datevalue,
          referenceNo,
          selectedbox
        );
        toast.success("Details Updated Successfully");
        setSearchUserName("");
        setSelectedUserId("");
        setSelectedBankId("");
        setSelectedBox("");
        setDateValue(null);
        setReferenceNo("");
        setDonations([]);
        navigate(-1);
      } catch (error) {
        toast.error("Failed to Update Details");
      }
    } else {
      const details = donations.map((donation) => ({
        amount: donation.amount,
        type: donation.typeId,
        purpose: donation.purposeId,
        onBehalfOf: donation.onBehalfofName,
      }));

      try {
        await donateApi(
          totalAmount,
          details,
          token,
          transactionType,
          selectedUserId,
          selectedBankId,
          datevalue,
          referenceNo,
          selectedbox
        );
        toast.success("Entry Added Successfully");
        setSearchUserName("");
        setSelectedUserId("");
        setSelectedBankId("");
        setSelectedBox("");
        setDateValue(null);
        setReferenceNo("");
        setDonations([]);
        navigate(-1);
      } catch (error) {
        toast.error("Failed to Add Entry");
      }
    }
  };
  function isValidGuid(value: string) {
    const guidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;
    return guidPattern.test(value);
  }

  const fetchUserData = async (searchTerm: string) => {
    try {
      const response = await getUserApi(searchTerm);
      setSearchUserResults(response);
    } catch (error) {
      toast.error("Not Able To Fetch user name");
    }
  };

  useEffect(() => {
    if (!searchUserName.trim()) {
      setSearchUserResults([]);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      fetchUserData(searchUserName);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUserName]);

  const fetchBanks = useCallback(async () => {
    try {
      const response = await getAllBankApi();
      const formattedBanks = response.map((bank: any) => ({
        id: bank.id,
        value: bank.id,
        label: `${bank.name} - ${bank.accountNumber}`,
      }));
      setBanks(formattedBanks);
    } catch (error) {
      toast.error("Error fetching bank data:");
    }
  }, []);

  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);

  const handleBankSelection = (value: string) => {
    setSelectedBankId(value);
  };

  const handleUserSelection = (value: string) => {
    const selectedUser = searchUserResults.find((user) => user.name === value);

    if (selectedUser) {
      setSearchUserName(selectedUser.name);
      setSelectedUserId(selectedUser.id);
      setIsDropdownOpen(false);
    } else {
      toast.error("No user found for the selected value");
    }
  };

  const handleCancel = () => {
    navigate("/admin/DonationDetails");
  };

  useEffect(() => {
    const fetchAllTaxonomies = async () => {
      try {
        const [typeResponse, purposeResponse, boxsequence] = await Promise.all([
          getTaxonomyApi("Donation Type"),
          getTaxonomyApi("Purpose"),
          getTaxonomyApi("Box"),
        ]);
        setBox(boxsequence);
        setDonationTypeOptions(typeResponse);
        setPurposeOptions(purposeResponse);
        // setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching taxonomies:", error);
        toast.error("Failed to load donation types and purposes");
      }
    };

    fetchAllTaxonomies();
  }, []);

  useEffect(() => {
    async function fetchDonationTypePurpose() {
      try {
        const fetchDonation = await getDonationTPApi("donationTPId");
        setDonationTP(fetchDonation);
      } catch (error) {
        toast.error("Failed to fetch Donation Type Purpose data.");
      }
    }

    fetchDonationTypePurpose();
  }, []);

  const donationOptions = donationTypeOptions.map((DonationType) => ({
    value: DonationType.value,
    id: DonationType.id,
    label: DonationType.value,
  }));

  const donationPurposeOptions = purposeOptions.map((DonationPurpose) => ({
    value: DonationPurpose.value,
    id: DonationPurpose.id,
    label: DonationPurpose.value,
  }));

  const totalAmount = donations.reduce(
    (sum, donation) => sum + Number(donation.amount || 0),
    0
  );

  const boxsequence = box.filter(
    (item) => item.type === "Box" && item.parentId == null
  );

  const handleBoxSelection = async (boxId: string) => {
    setSelectedBox(boxId);
    try {
      const boxDetails = await fetchBoxByTaxonomyIdApi(boxId);

      if (boxDetails?.userId) {
        const userDetails = await fetchUserByIdApi(boxDetails.userId);

        if (userDetails) {
          setSearchUserName(userDetails.name);
          setSelectedUserId(userDetails.id);
        } else {
          toast.error("Failed to find user details.");
          setSearchUserName("");
          setSelectedUserId("");
        }
      } else {
        setSearchUserName("");
        setSelectedUserId("");
      }
    } catch (error) {
      toast.error("Failed to fetch box or user details.");
    }
  };

  return (
    <div className="max-w-[80%] mb-4 px-1">
      <div className="mt-4 space-y-3">
        <h2 className=" text-center text-3xl font-bold text-blue-600 mb-9 mt-5 justify-center ">
          Add New Detail
        </h2>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Transaction By
          </label>
          <select
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedAccountType}
            onChange={(e) => setSelectedAccountType(e.target.value)}
            disabled={isEditDetails}
          >
            <option value="" disabled>
              Select Transaction By
            </option>
            {(isEditDetails
              ? ["EzPay", "Bank", "Cash", "Box"]
              : ["Bank", "Cash", "Box"]
            ).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {selectedAccountType === "Box" && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Box
            </label>
            <select
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={selectedbox || ""}
              onChange={(e) => handleBoxSelection(e.target.value)}
            >
              <option value="" disabled>
                Choose Box
              </option>
              {boxsequence.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700">
            User Name
          </label>
          <input
            type="text"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Type to search user..."
            value={searchUserName}
            onChange={(e) => {
              setSearchUserName(e.target.value);
              setIsDropdownOpen(true);
            }}
          />

          {/* Dropdown list */}
          {isDropdownOpen && (
            <div className="absolute z-10 mt-1 w-full max-h-60 overflow-y-auto bg-white shadow-lg rounded-md border border-gray-300">
              {searchUserName && searchUserResults.length > 0
                ? searchUserResults.map((user) => (
                    <div
                      key={user.name}
                      className="py-2 px-3 cursor-pointer hover:bg-blue-500 hover:text-white"
                      onClick={() => handleUserSelection(user.name)}
                    >
                      {user.name}
                    </div>
                  ))
                : searchUserName && (
                    <div className="py-2 px-3 text-gray-500">No data found</div>
                  )}
            </div>
          )}
        </div>

        {selectedAccountType !== "Cash" &&
          selectedAccountType !== "EzPay" &&
          selectedAccountType !== "Box" && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bank
                </label>
                <select
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={selectedBankId || ""}
                  onChange={(e) => handleBankSelection(e.target.value)}
                >
                  <option value="" disabled>
                    Choose Bank - Account number
                  </option>
                  {banks.map((bank: any) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Reference number
                </label>
                <input
                  required
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={referenceNo}
                  onChange={(e) => setReferenceNo(e.target.value)}
                  placeholder="Reference number"
                />
              </div>
            </>
          )}

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <input
            required
            type="date"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={datevalue ? datevalue.toISOString().slice(0, 10) : ""}
            onChange={(e) =>
              setDateValue(e.target.value ? new Date(e.target.value) : null)
            }
          />
        </div>
      </div>

      {/* Toggle button */}
      <div className="flex space-x-4">
        <button
          onClick={handleDrawerOpen}
          className="mt-4 flex items-center justify-center px-4 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
        >
          <FaPlus className="mr-2 w-4" />
          Details
        </button>
        <button
          onClick={handleCancel}
          className="mt-4 px-4 py-2 text-sm text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
        >
          Cancel
        </button>
      </div>

      {/* Backdrop for the drawer  */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={handleEditClose}
        style={{ backdropFilter: "blur(4px)", zIndex: 999 }}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out w-full md:w-1/3`}
        style={{
          borderRadius: "0.375rem",
          zIndex: 1000,
        }}
      >
        <div className="p-6">
          <h2 className=" text-center text-3xl font-bold text-blue-600 mb-9 mt-5 justify-center ">
            {isEditDetails ? "Updating Details" : "Adding New Details"}
          </h2>
          <form onSubmit={handleSubmitClick} className="space-y-4">
            <div>
              <label
                htmlFor="donationType"
                className="block text-sm font-medium text-globalBg mb-1"
              >
                Donation Type
              </label>
              <select
                id="donationType"
                required
                value={donationType?.value || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const selectedType = donationOptions.find(
                    (option) => option.value === value
                  );
                  if (selectedType) {
                    setDonationType(selectedType);
                    setDonationPurpose(null);
                  }
                }}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select Donation Type
                </option>
                {donationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col w-full">
              <label
                htmlFor="donation-purpose"
                className="text-sm font-medium text-gray-700 mb-1"
              >
                Donation Purpose
              </label>
              <select
                id="donation-purpose"
                className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 px-3 py-2"
                required
                value={donationPurpose?.value || ""}
                onChange={(e) => {
                  const value = e.target.value;

                  if (donationType?.value === "Normal / Sadaqah Nafilah") {
                    const selectedPurpose = donationPurposeOptions.find(
                      (p) => p.value === value
                    );
                    if (selectedPurpose) {
                      setDonationPurpose({
                        value: selectedPurpose.value,
                        id: selectedPurpose.id,
                        label: selectedPurpose.value,
                      });
                    }
                  } else {
                    const selectedDTP = donationTP
                      .filter((dtp) => dtp.type === donationType?.id)
                      .find((dtp) => {
                        const taxonomyPurpose = donationPurposeOptions.find(
                          (p) => p.id === dtp.purpose
                        );
                        return taxonomyPurpose?.value === value;
                      });

                    if (selectedDTP) {
                      const taxonomyPurpose = donationPurposeOptions.find(
                        (p) => p.id === selectedDTP.purpose
                      );
                      if (taxonomyPurpose) {
                        setDonationPurpose({
                          value: taxonomyPurpose.value,
                          id: selectedDTP.purpose,
                          label: taxonomyPurpose.value,
                        });
                      }
                    }
                  }
                }}
              >
                <option value="" disabled>
                  Select Donation Purpose
                </option>
                {donationType?.value === "Normal / Sadaqah Nafilah"
                  ? donationPurposeOptions.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.value}
                      </option>
                    ))
                  : donationTP
                      .filter((dtp: any) => dtp.type === donationType?.id)
                      .map((donationtp: any) => {
                        const taxonomyPurpose = donationPurposeOptions.find(
                          (purpose) => purpose.id === donationtp.purpose
                        );
                        if (!taxonomyPurpose) {
                          return undefined;
                        }
                        return {
                          value: taxonomyPurpose.value,
                          id: donationtp.purpose,
                          label: taxonomyPurpose.value,
                        };
                      })
                      .filter(
                        (
                          item: any
                        ): item is {
                          value: string;
                          id: string;
                          label: string;
                        } => item !== undefined
                      )
                      .map((item: any) => (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="donationAmount"
                className="block text-sm font-medium text-globalBg -700 mb-1"
              >
                Donation Amount
              </label>
              <input
                id="donationAmount"
                type="number"
                value={donationAmount || ""}
                onChange={(e) => setDonationAmount(Number(e.target.value))}
                required
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="onBehalfOf"
                className="block text-sm font-medium mb-1"
              >
                On Behalf of
              </label>
              <select
                id="onBehalfOf"
                value={onBehalfof}
                onChange={(e) => {
                  const value = e.target.value;
                  setOnBehalfof(value);
                  if (value === "Self") {
                    setOnBehalfofName("");
                  } else {
                    setOnBehalfofName(onBehalfofName);
                  }
                }}
                className="w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
              >
                <option value="">Select</option>
                <option value="Self">Self</option>
                <option value="Others">Others</option>
              </select>
            </div>

            {onBehalfof === "Others" && (
              <div className="mb-4">
                <label
                  htmlFor="onBehalfOfName"
                  className="block text-sm font-medium"
                >
                  Name
                </label>
                <input
                  id="onBehalfOfName"
                  type="text"
                  value={onBehalfofName}
                  onChange={(e) => setOnBehalfofName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded px-3 py-2"
                  required
                />
              </div>
            )}

            <div className="flex space-x-4 mt-6">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                {isEditModal ? "Update" : "Add"}
              </button>
              <button
                type="button"
                onClick={handleDrawerClose}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-6">
        {/* <h3 className="text-lg font-semibold">Donations</h3> */}
        {donations.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    Type
                  </th>
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    Purpose
                  </th>
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    Amount
                  </th>
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    On Behalfof
                  </th>
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    On Behalfof Name
                  </th>
                  <th className="font-normal border border: border-gray-300 px-4 py-2">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {donation.type}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {donation.purpose}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {donation.amount}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {donation.onBehalfof}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {donation.onBehalfofName}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        className="flex justify-center space-x-2 text-blue-600 hover:text-blue-800 "
                        onClick={() => {
                          handleEditClick(index);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>

                      <button
                        className="flex justify-center text-gray-600 hover:text-gray-800"
                        onClick={() => {
                          const newDonations = [...donations];
                          newDonations.splice(index, 1);
                          setDonations(newDonations);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 9.75v7.5a2.25 2.25 0 01-2.25 2.25h-4.5a2.25 2.25 0 01-2.25-2.25v-7.5m6.75-3.75v-.75a2.25 2.25 0 00-2.25-2.25h-3a2.25 2.25 0 00-2.25 2.25v.75m-3 0h12m-10.5 0h9"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-2 text-center"
                    colSpan={2}
                  >
                    Total
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {totalAmount}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-2"
                    colSpan={3}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        {donations.length > 0 && (
          <div className="flex space-x-4 mt-4">
            <button
              onClick={handleDonationButton}
              className=" flex items-center justify-center px-4 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            >
              Save
            </button>
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center px-4 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconArrowRight, IconBox, IconBuildingBank, IconCash, IconFileDownload, IconPaywall } from '@tabler/icons-react';
 
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';
import { donateHistoryApi, getHMTLDonationById } from '../../Api/Donation';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { ThemeContext } from '../theme/ThemeProvider';
import * as XLSX from 'xlsx';
 
 
 
type Donation = {
  userName: string;
  typeId: number;
  type: string;
  amount: number;
  purposeId: number;
  purpose: string;
  transactionId: string;
  date: Date;
};
 
 
export const DonationDetails = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [donationHistory, setDonationHistory] = useState<any[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [filteredDonations, setFilteredDonations] = useState<Donation[]>([]);
  const [activeTab, setActiveTab] = useState<number | null>(null);
 
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;
 
 
  const ITEMS_PER_PAGE = 10;
 
  const handleAddDonations = () => {
    navigate('/admin/DonationDetailForm');
  };
 
  const handleEditClick = (id: string) => {
    navigate('/admin/DonationDetailForm', { state: { id } });
  };
 
 
  const handleDonationHistoryApi = async () => {
    try {
      const response = await donateHistoryApi();
      if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      toast.error('Unable to get Donation History');
      return [];
    }
  };
 
 
 
  useEffect(() => {
    const fetchDonationHistory = async () => {
      try {
        const donations = await handleDonationHistoryApi();
        setDonationHistory(donations);
      }
      catch {
        console.error("Unable to fetch donation history")
      }
    };
    fetchDonationHistory();
  }, []);
 
  const handleDonationTab = (input: number) => {
    if (typeof input === "number") {
      const filtered = donationHistory.filter(
        (donation: any) => donation.transactionType === input);
      setFilteredDonations(filtered);
      setActiveTab(input);
    } else {
      setActiveTab(Number(input));
    }
  };
 
 
  useEffect(() => {
    const searchTerm = search.toLowerCase();
    const filtered = donationHistory.filter((donation) =>
      donation.userName?.toLowerCase().includes(searchTerm) ||
      donation.amount?.toString().includes(searchTerm) ||
      donation.type?.tostring().includes(searchTerm) ||
      new Date(donation.donatedOn).toLocaleDateString("en-GB").toLowerCase().includes(searchTerm)
 
    );
    setFilteredDonations(filtered);
    setCurrentPage(1);
  }, [search, donationHistory]);
 
 
  const currentExpenses = filteredDonations.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
 
 
  const totalPages = Math.ceil(filteredDonations.length / ITEMS_PER_PAGE);
 
 
  const handleDownloadReceipt = async (id: string) => {
    try {
      const response = await getHMTLDonationById(id);
      if (!response) {
        toast.error('Unable to fetch the receipt details.');
        return;
      }
      const { htmlContent } = response;
 
      const pdf = new jsPDF({ format: 'a4' });
 
      pdf.html(htmlContent, {
        callback: (doc) => {
          doc.save(`${id}-receipt.pdf`);
          toast.success('Receipt downloaded successfully.');
        },
        x: 10,
        y: 10,
      });
    } catch (error) {
      toast.error('Failed to download the receipt.');
    }
  };
 
  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      donationHistory.flatMap(donation =>
        donation.donationDetails.map((detail: any) => ({
          "Donation ID": donation.id,
          "Transaction Type": donation.transactionType,
          Name: donation.userName,
          Type: detail.donationType,
          Purpose: detail.donationPurpose,
          Amount: donation.amount,
          "Donated On": new Date(donation.donatedOn).toDateString(),
        }))
      )
    );
 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Donations');
    XLSX.writeFile(workbook, 'DonationHistory.xlsx');
  };
 
  return (
    <div className={`container h-full px-1 mx-auto  ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      {/* Title */}
      <div className="mb-4">
        <h2 className="text-3xl font-bold text-blue-600 mb-5 mt-5">Donation History</h2>
        <input
          type="text"
          placeholder="Search Donations"
          className={`w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"
            }`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
 
      <div className="relative">
        <div className="flex justify-around border-b border-gray-200 dark:border-gray-700">
          <button
            onClick={() => handleDonationTab(1)}
            className={`flex items-center h-10 px-4 text-sm font-medium focus:outline-none ${activeTab === 1
              ? "text-blue-600 border-blue-500 dark:text-blue-300 dark:border-blue-400"
              : "text-gray-600 hover:text-gray-800 dark:text-gray-300"
              }`}
          >
            <IconPaywall className="w-5 h-5 mr-2" />
            EzPay
          </button>
          <button
            onClick={() => handleDonationTab(2)}
            className={`flex items-center h-10 px-4 text-sm font-medium focus:outline-none ${activeTab === 2
              ? "text-blue-600 border-blue-500 dark:text-blue-300 dark:border-blue-400"
              : "text-gray-600 hover:text-gray-800 dark:text-gray-300"
              }`}
          >
            <IconBuildingBank className="w-5 h-5 mr-2" />
            Bank
          </button>
          <button
            onClick={() => handleDonationTab(3)}
            className={`flex items-center h-10 px-4 text-sm font-medium focus:outline-none ${activeTab === 3
              ? "text-blue-600 border-blue-500 dark:text-blue-300 dark:border-blue-400"
              : "text-gray-600 hover:text-gray-800 dark:text-gray-300"
              }`}
          >
            <IconCash className="w-5 h-5 mr-2" />
            Cash
          </button>
          <button
            onClick={() => handleDonationTab(4)}
            className={`flex items-center h-10 px-4 text-sm font-medium focus:outline-none ${activeTab === 4
              ? "text-blue-600 border-blue-500 dark:text-blue-300 dark:border-blue-400"
              : "text-gray-600 hover:text-gray-800 dark:text-gray-300"
              }`}
          >
            <IconBox className="w-5 h-5 mr-2" />
            Box
          </button>
        </div>
 
        {/* Blue Line Indicator */}
        {activeTab !== null && (
          <div
            className="absolute bottom-0 h-0.5 bg-blue-500 transition-all duration-300"
            style={{
              width: `${100 / 4}%`,
              transform: `translateX(${(activeTab - 1) * 100}%)`,
            }}
          />
        )}
      </div>
 
      {/* Table */}
      <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-5">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
 
            <tr>
              <th
                scope="col"
                className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400" >
                Edit
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                <span>Name</span>
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                <span> Amount</span>
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                <span>Donated On</span>
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                Type
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                View Details
              </th>
              <th
                scope="col"
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                Action
              </th>
            </tr>
          </thead>
          <div></div>
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
            {filteredDonations.length > 0 ? (
              currentExpenses.map((donation: any, index: any) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="flex items-center gap-x-6">
                        <button
                          onClick={() => handleEditClick(donation.id)}
                          className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                    <td className={`px-4 py-4 text-sm font-medium ${isDarkMode ? " text-gray-500" : " text-gray-500"}`} > {donation.userName}</td>
                    <td className={`px-4 py-4 text-sm   ${isDarkMode ? " text-gray-500" : " text-gray-500"}`} >
                      {donation.amount}
                    </td>
                    <td className={`px-4 py-4 text-sm  ${isDarkMode ? " text-gray-500" : " text-gray-500"}`} >
                      {new Date(donation.donatedOn)
                        .toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                        .replace(/\//g, "/")}
                    </td>
                    <td className={`px-4 py-4 text-sm   ${isDarkMode ? " text-gray-500" : " text-gray-500"}`} >{donation.transactionType}</td>
                    <td className={`px-4 py-4 text-sm   ${isDarkMode ? " text-gray-500" : " text-gray-500"}`} >
                      <button
                        className="text-blue-600 hover:underline"
                        onClick={() => {
                          if (expandedIndex === index) {
                            setExpandedIndex(null);
                          } else {
                            setExpandedIndex(index);
                          }
                        }}
                      >
                        Show
                      </button>
                    </td>
                    <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                      <button
                        className="text-blue-500 px-3 py-1 rounded flex items-center hover:text-blue-700"
                        onClick={() => handleDownloadReceipt(donation.id)}
                      >
                        <IconFileDownload size={30} className="mr-2" />
                      </button>
 
 
                    </td>
                  </tr>
                  {expandedIndex === index && (
                    <tr>
                      <td colSpan={7} className=" px-4 py-2">
                        <div>
                          {/* <h4 className="font-semibold mb-4">Donation Details</h4> */}
                          <table className="w-full border-collapse border border-gray-200">
                            <thead className="bg-gray-200">
                              <tr>
                                <th className="font-normal px-4 py-2 text-center text-gray-500">Type</th>
                                <th className="font-normal px-4 py-2 text-center text-gray-500">Purpose</th>
                                <th className="font-normal px-4 py-2 text-center text-gray-500">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {donation.donationDetails.map((detail: any, detailIndex: number) => (
                                <tr key={detailIndex} className="hover:bg-gray-50">
                                  <td className="border border-gray-300 px-4 py-2 text-center text-gray-500">
                                    {detail.donationType}
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2 text-center text-gray-500">
                                    {detail.donationPurpose}
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2 text-center text-gray-500  ">
                                    {detail.amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              null
            )}
          </tbody>
        </table>
      </div>
 
      <div className="flex space-x-4 mt-4">
        <button
          onClick={handleAddDonations}
          className="flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
        >
          <FaPlus className="mr-2" />
          Donation
        </button>
 
        <button
          onClick={handleDownloadExcel}
          className=" font-medium bg-blue-600 hover:bg-blue-500 text-white focus:ring-blue-300 focus:ring-opacity-80 px-4 py-2 rounded-md flex items-center"
        >
          <IconFileDownload className="mr-2" />
          Download
        </button>
      </div>
 
 
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          <IconArrowLeft />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${currentPage === index + 1
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700 hover:bg-gray-400"
              }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};


const Projects =
  [
    {
      "title": "Masjid E TajushShariah (Construction)",
      "image": "images/ComingSoon.jpg",
      "ratio": "1920 / 1080",
      "description": "We are planning to construct a Masjid near by Tolichowki, on the backside of Seven Tombs Road in Puppalguda.",
      "point1": "Planned Land Size: 100 Sq Yard",
      "point2": "Madrasa: 100 Sq Yard.",
      "point3": "Approximate Land Cost: 72 Lakhs",
      "point4": "Approximate Build Cost (G+3): 55 Lakhs",
      "to": "/donate",
      "Badge": "New",
      "mainlink": "/MasjidETajushShariah"
    },
    {
      "title": "Madrasa E TajushShariah (Construction)",
      "image": "images/ComingSoon.jpg",
      "ratio": " 1920 / 1080",
      "description": "We are planning to construct a Madrasa with Masjid E TajushShariah",
      "point1": "Planned Land Size: 100 Sq Yard",
      "point2": "Madrasa: 100 Sq Yard",
      "point3": "Approximate Land Cost: 72 Lakhs",
      "point4": "Approximate Build Cost (G+3): 55 Lakhs",
      "to": "/donate",
      "Badge": "New",
      "mainlink": "/MadrasaETajushShariahLand"
    },
    {
      "title": "Madrasa E TajushShariah",
      "image": "images/ComingSoon.jpg",
      "ratio": " 1920 / 1080",
      "description": "The Madrasa is now located in Quli Qutb Shah Nagar, Tolichowki.",
      "point1": "Available for Offline Hifz + Aalimiyat with Food + Stay",
      "point2": "Available for All Age Groups",
      "point3": "Seperate arrangements For Ladies",
      "point4": "Support Online Classes and Q&A",
      "to": "/donate",
      "Badge": "Running",
      "mainlink": "/MadrasaETajushShariah"
    },
    {
      "title": "Digitalization for Islamic Learning",
      "image": "images/Digital.jpg",
      "ratio": "1920 / 1080",
      "description": "We will be providing digital tools to help increase productivity.",
      "point1": "Digital Tools for Ulema to spread Islamic Learning",
      "point2": "Digital Tools for Common People",
      "point3": "Platform to connect Common People with Ulema",
      "point4": "Many more to come",
      "to": "/donate",
      "Badge": "New",
      "mainlink":"/DigitalSupport" 
    }
  ]

export default Projects
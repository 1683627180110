import React, { useContext } from 'react';
import { IconBuildingMosque, IconSchool } from '@tabler/icons-react';
import { ThemeContext } from '../../theme/ThemeProvider';

const BankAccounts = () => {

    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;

    return (
        <div className={`py-8 container mx-auto ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
            <h1 className="text-blue-400 text-4xl font-bold text-center mb-4">
                Donation
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:mx-72 gap-3 px-2">
                {/* Card for Masjid */}
                <div className={`flex rounded-md p-4 hover:scale-[1.01] hover:shadow-md transition-transform duration-150 ease-in-out lg:mx-4 border  ${isDarkMode ? "border-[#424242]" : ""}`}>
                    <div>
                        <div className="flex items-center justify-center w-10 h-10 bg-blue-400 rounded-md">
                            <IconBuildingMosque className="w-7 h-7 text-white" stroke={1.5} />
                        </div>
                        <p className={`mt-1 font-medium ${isDarkMode ? "text-white" : "text-black"}`}>For Masjid</p>
                        <p className="mt-2 text-sm text-gray-500">
                            Bank Name: ICICI <br />
                            A/c Number: 236605002974 <br />
                            A/c Name: TajushShariah Foundation <br />
                            IFSC Code: ICIC0002366 <br />
                            Branch: Mothi Nagar
                        </p>
                    </div>
                    <div className="ml-auto mt-10 lg:mt-0">
                        <img
                            className="w-40"
                            src="images/ICICI.jpg"
                            alt="mstajushshariahfoundation.eazypay@icici"
                        />
                    </div>
                </div>
                <div className={`flex border rounded-md p-4 hover:scale-[1.01] hover:shadow-md transition-transform duration-150 ease-in-out lg:mx-4 ${isDarkMode ? "border-[#424242]" : "border-gray-300"}`}>
                    <div>
                        <div className="flex items-center justify-center w-10 h-10 bg-blue-400 rounded-md">
                            <IconSchool className="w-7 h-7 text-white" stroke={1.5} />
                        </div>
                        <p className={`t-1 font-medium ${isDarkMode ? "text-white" : "text-black"}`}>For Madrasa</p>
                        <p className="mt-2 text-sm text-gray-500">
                            Bank Name: HDFC <br />
                            A/c Number: 50200088953374 <br />
                            A/c Name: TajushShariah Foundation <br />
                            IFSC Code: HDFC0008851 <br />
                            Branch: Tolichowki
                        </p>
                    </div>
                    <div className="ml-auto mt-10 lg:mt-0">
                        <img
                            className="w-40"
                            src="images/HDFC.jpg"
                            alt="mstajushshariahfoundation.eazypay@icici"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BankAccounts;
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../theme/ThemeProvider';

const MadrasaETajushShariahLand = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;

    return (
        <div className={`${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
            <div className="lg:mx-72 md:mx-8 mx-4 text-justify py-5">
                <h1 className="text-2xl md:text-2xl font-bold text-center text-blue-500 mb-4">
                    Madrasa E TajushShariah
                </h1>

                <p className="text-[#868EA4] mb-4 text-sm md:text-base">
                    TajushShariah Foundation is formed with a mission -{' '}
                    <span className={`font-semibold ${isDarkMode ? "text-white" : "text-black"}`}>
                        A step towards <span className="text-blue-500">digitalization</span> for Islamic Learning
                    </span>
                </p>
                <p className="text-[#868EA4] mb-4 text-sm md:text-base">
                    Madrasa E TajushShariah is one of the main projects running with the mission to provide education to the students of Telangana (especially Hyderabad) under the spiritual guidance of Alahazrat Imam Ahmad Raza Barelvi to create the force of local Ulema for the future.
                </p>
                <p className="text-[#868EA4] mb-8 text-sm md:text-base">
                    We also commit to provide modern education (English, Mathematics, and Computer) so they can compete in the world of digitalization.
                </p>

                <h2 className="text-xl md:text-2xl font-bold text-center text-blue-500 mb-4">
                    Estimate
                </h2>

                <div className="overflow-x-auto mb-8">
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="text-left">
                                <th className={`px-4 py-2 text-lg border ${isDarkMode ? "text-[#c9c9c9] border-[#424242]" : "text-black"}`}></th>
                                <th className={`px-4 py-2 text-lg border ${isDarkMode ? "text-[#c9c9c9] border-[#424242]" : "text-black"}`}>Details</th>
                                <th className={`px-4 py-2 text-lg border text-right ${isDarkMode ? "text-[#c9c9c9] border-[#424242]" : "text-black"}`}>Amount</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            <tr className={`text-left font-light ${isDarkMode ? "bg-[#2e2e2e]" : "bg-white"}`}>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    Land Cost (Approx.)
                                </td>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    1 Sq Ft
                                </td>
                                <td className={`px-4 py-2 text-right ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    ₹8,500
                                </td>
                            </tr>
                            <tr className={`text-left font-light ${isDarkMode ? "bg-[#242424]" : "bg-white"}`}>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    Land Size (Approx.)
                                </td>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    900 Sq Ft
                                </td>
                                <td className={`px-4 py-2 text-right ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    ₹72,00,000
                                </td>
                            </tr>
                            <tr className={`text-left font-light ${isDarkMode ? "bg-[#2e2e2e]" : "bg-white"}`}>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    Construction Cost (Approx.)
                                </td>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    1 Sq Ft
                                </td>
                                <td className={`px-4 py-2 text-right ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    ₹1,500
                                </td>
                            </tr>
                            <tr className={`text-left font-light ${isDarkMode ? "bg-[#242424]" : "bg-white"}`}>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    G + 3 Floor Construction Cost (Approx.)
                                </td>
                                <td className={`px-4 py-2 ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    3,600 Sq Ft
                                </td>
                                <td className={`px-4 py-2 text-right ${isDarkMode ? "text-[#c9c9c9] border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    ₹54,75,000
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr className={`font-semibold text-blue-400 text-left ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
                                <th colSpan={2} className={`px-4 py-2 ${isDarkMode ? "border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    Total Cost (Approx.)
                                </th>
                                <th className={`px-4 py-2 text-right ${isDarkMode ? "border border-[#424242]" : "border border-[#E5E7EB]"}`}>
                                    ₹12,675,000
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="text-left">
                    <Link to="/donate">
                        <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 text-sm md:text-base">
                            Donate Now
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MadrasaETajushShariahLand;

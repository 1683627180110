const Data=[
    {
         "title" : "Masjid + Madrasa Setup",
       "description" :
          "In some places, we need to establish a Masjid and Madrasa from the ground up and provide end-to-end support.",
        "icon":"IconGauge"
        },
       {
         "title": "Ulema Support",
         "description": "In some places, Ulema need additional financial support for a period of time to become independent in the future.",
         "icon": "IconUser"
        },
       {
         "title": "Digital Platform",
         "description":
           "There is a real need for digitization, where we can focus on creating different solutions in the form of websites and apps that will support Ulema and madrasas to increase their productivity.",
          "icon": "IconCookie"
        }
      ]
    export default Data;
import React, { useContext } from 'react';
import { IconGauge, IconCookie, IconUser } from '@tabler/icons-react';
import Data from '../../../Jsondata/Ourfocus';
import { ThemeContext } from "../../theme/ThemeProvider";

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

function getIconComponent(iconName: string): React.FC<any> {
  switch (iconName) {
    case 'IconGauge':
      return IconGauge;
    case 'IconCookie':
      return IconCookie;
    case 'IconUser':
      return IconUser;
    default:
      return IconGauge;
  }
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  return (
    <div className="flex flex-col items-start text-left">
      <div
        className={`p-3 flex items-center rounded-full text-blue-500 ${isDarkMode ? "bg-blue-900" : "text-black bg-gray-100"
          }`}
      >
        <Icon className="w-6 h-5" stroke={1.5} />
      </div>
      <h3
        className={`mt-4 mb-2 font-normal text-base ${isDarkMode ? "text-[#c1bfb7]" : "text-gray-800"
          }`}
      >
        {title}
      </h3>
      <p className="text-sm text-gray-500 leading-relaxed">{description}</p>
    </div>
  );
}

const FocusTo = () => {
  const features = Data.map((feature, index) => {
    const IconComponent = getIconComponent(feature.icon);
    return <Feature {...feature} icon={IconComponent} key={index} />;
  });

  return (
    <div className="ml-5 md:mr-5 lg:mr-0">
      <h2 className="text-4xl font-bold text-center text-blue-400">Our Focus</h2>
      <div
        className="grid gap-9 mt-4 md:grid-cols-2 lg:grid-cols-3 lg:justify-center lg:mx-[300px] "
      >
        {features}
      </div>
    </div>
  );
};

export default FocusTo;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { getBankApi } from "../../Api/Bank";
import { FaPlus } from "react-icons/fa";
import { ThemeContext } from "../theme/ThemeProvider";




const BankDetails = () => {
  interface Bank {
    id: string;
    name: string;
    branch: string;
    ifscCode: string;
    accountNumber: string;
  }

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [banks, setBanks] = useState<Bank[]>([]);

  const ITEMS_PER_PAGE = 10;
  const navigate = useNavigate();

  const handleSort = (field: string) => setSortBy(field);

  const handleEditClick = (id: string) => {
    navigate("/admin/BankDetailsForm", { state: { id } });
  };

  const handleAddClick = () => {
    navigate('/admin/BankDetailsForm');
  };

  // const renderEditButton = (id: string) => (
  //   <IconPencil
  //     className="cursor-pointer text-gray-600 hover:text-blue-500"
  //     onClick={() => handleEditClick(id)}
  //   />
  // );

  const handleApiCallClick = async () => {
    try {
      const bankData = await getBankApi();
      setBanks(bankData);
    } catch (error) {
      alert("Failed to fetch bank data. Some problem occurred. Please try again.");
    }
  };

  useEffect(() => {
    handleApiCallClick();
  }, []);

  const sortedBanks = banks.slice().sort((a, b) => {
    if (sortBy) {
      const aValue = a[sortBy as keyof Bank];
      const bValue = b[sortBy as keyof Bank];
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
    }
    return 0;
  });

  const filteredBanks = sortedBanks.filter((item) =>
    Object.values(item).some(
      (value) =>                                                                                  
        typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastBank = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstBank = indexOfLastBank - ITEMS_PER_PAGE;
  const currentBanks = filteredBanks.slice(indexOfFirstBank, indexOfLastBank);

  const totalPages = Math.ceil(filteredBanks.length / ITEMS_PER_PAGE);

  return (
    <div className={`container h-full px-1 mx-auto  ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <h2 className="text-3xl font-bold text-blue-600 mb-4 mt-4">Bank Details</h2>
      <input
        type="text"
        placeholder="Search Bank"
        className={`w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500 ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"}`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />


      <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-5">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr >

              <th className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400" >
                Edit </th>
              <th
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"}`}
                onClick={() => handleSort("name")}
              >
                Bank {sortBy === "name" && "↑"}
              </th>
              <th
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"}`}
                onClick={() => handleSort("branch")}
              >
                Branch {sortBy === "branch" && "↑"}
              </th>
              <th
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"}`}
                onClick={() => handleSort("ifscCode")}
              >
                IFSC Code {sortBy === "ifscCode" && "↑"}
              </th>
              <th
                className={`px-4 py-3.5 text-md font-normal text-left rtl:text-right ${isDarkMode ? " text-gray-500" : " text-gray-500"}`}
                onClick={() => handleSort("accountNumber")}
              >
                Account Number {sortBy === "accountNumber" && "↑"}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
            {currentBanks.map((bank) => (
              <tr key={bank.id} className="hover:bg-gray-50">
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div className="flex items-center gap-x-6">
                    <button
                      onClick={() => handleEditClick(bank.id)}
                      className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
                <td className={`px-4 py-4 text-sm font-medium ${isDarkMode ? " text-gray-500 " : " text-gray-500"
                  }`} >
                  {bank.name}
                </td>
                <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >

                  {bank.branch}
                </td>
                <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >
                  {bank.ifscCode}
                </td>
                <td className={`px-4 py-4 text-sm ${isDarkMode ? " text-gray-500" : " text-gray-500"
                  }`} >

                  {bank.accountNumber}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button onClick={handleAddClick} className="mb-5 mt-3 flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
        <FaPlus className="mr-2 font-medium " />
        Bank
      </button>

      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          <IconArrowLeft />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${currentPage === index + 1
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-700 hover:bg-gray-400"
              }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};

export default BankDetails;

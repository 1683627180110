import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { donateHistoryByUserIdApi, getHMTLDonationById } from '../../Api/Donation';
import Cookies from 'universal-cookie';
import toast from 'react-hot-toast';
import { IconArrowLeft, IconArrowRight, IconFileDownload } from '@tabler/icons-react';
import jsPDF from 'jspdf';
import { ThemeContext } from "../theme/ThemeProvider";



const DonationHistory = () => {
  const cookies = new Cookies();
  const token = cookies.get("authToken")
  const [donationHistory, setDonationHistory] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ITEMS_PER_PAGE = 10;
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  useEffect(() => {
    const fetchDonationHistory = async () => {
      try {
        const res: any = await donateHistoryByUserIdApi();
        setDonationHistory(res);
      } catch (error) {
        toast.error('Failed to fetch donation history');
      }
    };

    if (token) {
      fetchDonationHistory();
    }
  }, [token]);




  const filteredDonations = donationHistory.filter((donation) => {

    const searchTerm = search.toLowerCase();

    return (
      donation.id?.toString().toLowerCase().includes(searchTerm) ||
      donation.amount?.toString().includes(searchTerm) ||
      new Date(donation.donatedOn)
        .toLocaleDateString("en-GB")
        .toLowerCase()
        .includes(searchTerm)
    );
  });

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = filteredDonations.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredDonations.length / ITEMS_PER_PAGE);
  const handleDownloadReceipt = async (id: string) => {
    try {
      const response = await getHMTLDonationById(id);
      if (!response) {
        toast.error('Unable to fetch the receipt details.');
        return;
      }
      const { htmlContent } = response;

      const pdf = new jsPDF({ format: 'a4' });


      pdf.html(htmlContent, {
        callback: function (doc) {
          doc.save(`${id}-receipt.pdf`);
          toast.success('Receipt downloaded successfully.');
        },
        x: 10,
        y: 10,
      });

    } catch (error) {
      console.error('Failed to download the receipt:', error);
      toast.error('Failed to download the receipt.');
    }
  };

  return (
    <div className={`py-12 ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <div className="lg:mx-72 md:mx-4 mx-2 ">
        <h1 className="text-center font-bold text-3xl text-blue-400 mb-4">Donation History</h1>
        <input
          type="text"
          placeholder="Search by Transaction ID, Amount, or Date"
          className={`w-full p-2 mb-4 text-sm font-normal rounded border focus:outline-none ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 text-white" : "border-gray-300"}`}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            setCurrentPage(1);
          }}
        />
        <div className="overflow-x-auto">
          <table className={`w-full mb-8 border-collapse`}>
            <thead>
              <tr className={`text-sm font-medium  ${isDarkMode ? " text-gray-300" : "text-black"}`}>
                <th className={`border p-2 text-left ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Transaction ID</th>
                <th className={`border p-2 text-right ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Amount</th>
                <th className={`border p-2 text-left  ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Donated On</th>
                <th className={`border p-2 text-left ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>View Details</th>
                <th className={`border p-2 text-left ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Receipt</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((donation: any, index: number) => (
                <React.Fragment key={donation.id}>
                  <tr className={`text-sm font-normal ${isDarkMode ? " text-gray-300" : "text-black"}`}>
                    <td className={`border p-2 ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>{donation.id}</td>
                    <td className={`border p-2 md:pl-24 text-right ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>{donation.amount}</td>
                    <td className={`border p-2  md:pr-24 ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                      {new Date(donation.donatedOn).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </td>
                    <td className={`border  p-2  md:pr-24 ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                      <button
                        className="text-blue-400 underline hover:text-blue-600"
                        onClick={() => {
                          if (expandedIndex === index) {
                            setExpandedIndex(null);
                          } else {
                            setExpandedIndex(index);
                           
                          }
                        }}
                      >
                        Show
                      </button>
                    </td>
                    <td className={`border   ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                      <button
                        className="text-blue-500 px-3 py-1 rounded flex items-center hover:text-blue-700"
                        onClick={() => handleDownloadReceipt(donation.id)}
                      >
                        <IconFileDownload size={30} className="mr-2" />
                      </button>
                    </td>
                  </tr>
                  {expandedIndex === index && (
                    <tr>
                      <td colSpan={7} className=" px-4 py-2">
                        <div>
                          <table className="w-full border-collapse border border-gray-200">
                            <thead>
                              <tr className={`text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`} >
                                <th className={`border p-2 text-left ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Type</th>
                                <th className={`border p-2 text-left ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Purpose</th>
                                <th className={`border p-2 md:pl-20 text-right ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {donation.donationDetails.map((detail: any, detailIndex: number) => (
                                <tr key={detailIndex} className={`text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"}`}>
                                  <td className={`border p-2 md:pr-20 ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                                    {detail.donationType}
                                  </td>
                                  <td className={`border p-2 md:pr-20 ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                                    {detail.donationPurpose}
                                  </td>
                                  <td className={`border p-2 md:pl-20 text-right ${isDarkMode ? "border-gray-700" : "border-gray-300"}`}>
                                    {detail.amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}


                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center mt-4">
          <div className="flex items-center">
            <button
              onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
              className={`p-2   ${isDarkMode ? "text-gray-300 hover:text-gray-400" : "hover:text-black text-gray-600"} `}
            >
              <IconArrowLeft />
            </button>
            <span className={`mx-4 ${isDarkMode ? "text-gray-300" : "text-black"}`}>{currentPage} / {totalPages}</span>
            <button
              onClick={() =>
                currentPage < totalPages && setCurrentPage(currentPage + 1)
              }
              className={`p-2   ${isDarkMode ? "text-gray-300 hover:text-gray-400" : "hover:text-black text-gray-600"} `}
            >
              <IconArrowRight />
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default DonationHistory;

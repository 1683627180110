import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";
import Welcome from "../welcome/Welcome";
import FocusTo from "./FocusTo";
import HomeProjects from "./HomeProjects";
import BankAccounts from "./BankAccounts";
import HomeContact from "./HomeContact";
import Scrolltopbutton from "../../scrolltopbutton/Scrolltopbutton";
import BenevityPartner from "../../banevitypartner/BenevityPartner";

const Home = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  // const userProfile = useAppSelector((state) => state.userProfile.userData);
  // const isLoggedIn = useAppSelector((state) => state.userProfile.isLoggedIn);

  return (
    <div
      className={`${isDarkMode
        ? "bg-globalBg border-b border-gray-700"
        : "bg-[#ffff] border-b border-gray-200"
        } `}
    >
      <Welcome />
      <FocusTo />
      <HomeProjects />
      <BenevityPartner />
      <BankAccounts />
      <HomeContact />
      <Scrolltopbutton />

    </div>
  );
};

export default Home;



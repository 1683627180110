import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;



const cookies = new Cookies();

export const addExpenseApi = async (
    Mode: string,
    // selectedBankId: string,
    From: string,
    Type: String,
    Purpose: String,
    Amount: number,
    Desc: string
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${apiUrl}/expense/create`,
        {
          Mode,
          // selectedBankId,
          From,
          Type,
          Purpose,
          Amount,
          Desc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  
  export const getExpenseApi = async (expenseId: string) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.get(
        `${apiUrl}/expense/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export const updateExpenseApi = async (
    Id: string,
    Mode: string,
    // selectedBankId: string,
    From: string,
    Purpose: String,
    Amount: number,
    Type: string,
    Desc: string
  ) => {
    const token = cookies.get("authToken");
    try {
      const response = await axios.post(
        `${apiUrl}/expense/update`,
        {
          Id,
          Mode,
          // selectedBankId,
          From,
          Purpose,
          Amount,
          Type,
          Desc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;



const cookies = new Cookies();

export const getDonationTPApi = async(donationTP: string) => {
    const token = cookies.get('authToken')
    try {
      const response = await axios.get(`${apiUrl}/donationtypepurpose/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return false;
    }
  };
  
  
  export const addDonationTPApi = async (
    Type: string,
    Purpose: string
  ) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.post(
        `${apiUrl}/donationtypepurpose/create`,
        {
          Type,
          Purpose
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const deleteDonationTPApi = async (
    // id: string,
    Type: string,
    Purpose: string
  ) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.delete(`${apiUrl}/donationtypepurpose/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { 
          // id,
          Type, 
          Purpose 
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
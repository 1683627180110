import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchUsersApi,
  fetchTaxonomiessApi,
  fetchUserByIdApi,
  fetchTaxonomyByIdApi,
  addBoxApi,
  updateboxApi,
} from "../../Api/Box";

const BoxForm = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [taxonomies, setTaxonomies] = useState<any[]>([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState("");
  const [editMode, setEditMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const fetchUsersData = async () => {
    const data = await fetchUsersApi();
    if (data) {
      setUsers(data as any[]);
    } else {
      console.error("Failed to fetch users");
    }
  };

  const fetchTaxonomiesData = async () => {
    const data = await fetchTaxonomiessApi();
    if (data) {
      setTaxonomies(data as any[]);
    } else {
      console.error("Failed to fetch taxonomies");
    }
  };

  const prefillFields = async (userId: string, boxId: string) => {
    try {
      const user = await fetchUserByIdApi(userId);
      const taxonomy = await fetchTaxonomyByIdApi(boxId);

      if (user) setSelectedUser(user.id);
      if (taxonomy) setSelectedTaxonomy(taxonomy.id);

      setEditMode(true);
    } catch (error) {
      console.error("Failed to prefill fields:", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
    fetchTaxonomiesData();

    const state = location.state as { boxId: string; userId: string };
    if (state?.boxId && state?.userId) {
      prefillFields(state.boxId, state.userId);
    }
  }, [location.state]);

  const handleEditClick = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUser || !selectedTaxonomy) {
      alert("Please select both User and Box.");
      return;
    }
    try {
      await updateboxApi(selectedUser, selectedTaxonomy);
      alert(editMode ? "Box updated successfully!" : "Box added successfully!");
      setSelectedUser("");
      setSelectedTaxonomy("");
      navigate("/admin/box");
    } catch (error) {
      console.error("Error adding box", error);
    }
  };

  const handleAddClick = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUser || !selectedTaxonomy) {
      alert("Please select both User and Box.");
      return;
    }

    try {
      await addBoxApi(selectedUser, selectedTaxonomy);
      alert(editMode ? "Box updated successfully!" : "Box added successfully!");
      setSelectedUser("");
      setSelectedTaxonomy("");
      navigate("/admin/box");
    } catch (error) {
      console.error("Error adding box", error);
    }
  };

  const handleCancelClick = () => {
    setSelectedUser("");
    setSelectedTaxonomy("");
    navigate("/admin/box");
  };

  return (
    <div className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
      <div>
        <form>
          <h1 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white mb-4">
            {editMode ? "Edit Box" : "Add Box"}
          </h1>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              User <span className="text-red-500">*</span>
            </label>
            <select
              required
              className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">Select a User</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Box <span className="text-red-500">*</span>
            </label>
            <select
              required
              className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              value={selectedTaxonomy}
              onChange={(e) => setSelectedTaxonomy(e.target.value)}
              disabled={editMode}
            >
              <option value="">Select a Box</option>
              {taxonomies.map((taxonomy) => (
                <option key={taxonomy.id} value={taxonomy.id}>
                  {taxonomy.type}
                </option>
              ))}
            </select>
          </div>

          <div className="flex space-x-4 mt-6 justify-end">
            <button
              type="submit"
              onClick={editMode ? handleEditClick : handleAddClick}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              {editMode ? "Edit" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancelClick}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BoxForm;

import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import toast from "react-hot-toast";

import {
  addExpenseApi,
  updateExpenseApi,
  getExpenseApi,
} from "../../Api/Expense";
import { getTypeApi } from "../../Api/Taxonomy";
import { getAllBankApi } from "../../Api/Bank";
import { getUserByRoleType } from "../../Api/User";
import { ThemeContext } from "../theme/ThemeProvider";

type ExpenseType = {
  id: string;
  Id: string;
  value: string;
  parentId: string | null;
  type: string;
  code: number;
  Purpose: string;
  Mode: string;
  From: string;
};

type BanksData = {
  id: string;
  Id: string;
  value: string;
  Mode: string;
  From: string;
  Name: string;
  label: string;
};

type AdminsData = {
  label: string;
  id: string;
  value: string;
};

interface Option {
  id: number;
  value: string;
  label: string;
}

const ExpenseModes: Option[] = [
  {
    id: 1,
    value: "Bank",
    label: "Bank",
  },
  {
    id: 2,
    value: "Cash",
    label: "Cash",
  },
];

export function ExpenseForm() {
  const [data, setData] = useState<ExpenseType[]>([]);
  const [DataPurpose, setDataPurpose] = useState<ExpenseType[]>([]);
  const [BankData, setBankData] = useState<BanksData[]>([]);
  const [AdminData, setAdminData] = useState<AdminsData[]>([]);
  const [selectedExpenseType, setSelectedExpenseType] = useState<string | null>(
    null
  );
  const [selectedExpenseSubType, setSelectedExpenseSubType] = useState<
    string | null
  >(null);
  const [expensePurpose, setExpensePurpose] = useState<string | null>(null);
  const [expenseAmount, setExpenseAmount] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [ExpenseOn, setExpenseOn] = useState<string>("");
  const [isEdit, setIsEditMode] = useState(false);
  const [id, setId] = useState<string>("");
  const [selectedexpenseMode, setExpenseMode] = useState<Option | null>(null);

  const [From, setFrom] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { id: locationId } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTypeApi("Expense Category");
        setData(response);
        const res = await getTypeApi("Purpose");
        setDataPurpose(res);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const fetchBanks = useCallback(async () => {
    try {
      const response = await getAllBankApi();
      const formattedBanks = response.map((bank: any) => ({
        value: bank.id,
        label: `${bank.name} - ${bank.accountNumber}`,
      }));
      setBankData(formattedBanks);
    } catch (error) {
      toast.error("Error fetching bank data.");
    }
  }, []);

  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);

  const fetchAdminData = async () => {
    try {
      const response = await getUserByRoleType(2);
      const formattedAdminData = response.map((admin: any) => ({
        value: admin.id,
        label: admin.name,
      }));
      setAdminData(formattedAdminData);
    } catch (error) {
      toast.error("Error fetching Admin data");
    }
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  useEffect(() => {
    if (locationId) {
      setId(locationId);
    }
  }, [locationId]);

  useEffect(() => {
    async function fetchExpense() {
      if (id) {
        try {
          const expenseData = await getExpenseApi(id);
          const expenseToEdit = expenseData.find(
            (expense: any) => expense.id === id
          );
          if (expenseToEdit) {
            setId(expenseToEdit.id);

            const expenseMode = ExpenseModes.find(
              (mode) => mode.value === expenseToEdit.mode
            );
            setExpenseMode(expenseMode || null);

            const fromId =
              expenseMode?.value === "Bank"
                ? BankData.find((bank) => bank.id === expenseToEdit.From)?.value
                : AdminData.find((name) => name.id === expenseToEdit.From)
                    ?.value;

            setFrom(fromId || "");

            const typeId = data.find(
              (d) => d.value === expenseToEdit.type
            )?.parentId;
            const subTypeId = data.find(
              (d) => d.value === expenseToEdit.type
            )?.id;
            const purposeId = DataPurpose.find(
              (purpose) => purpose.value === expenseToEdit.purpose
            )?.id;

            setSelectedExpenseType(typeId ? typeId : "");
            setSelectedExpenseSubType(subTypeId ? subTypeId : "");
            setExpensePurpose(purposeId ? purposeId : "");
            setExpenseAmount(expenseToEdit.amount || 0);
            setDescription(expenseToEdit.desc || "");
            setExpenseOn(expenseToEdit.ExpenseOn || "");
            setIsEditMode(true);
          }
        } catch (error) {
          toast.error("Error fetching expense data.");
        }
      }
    }

    if (
      data.length &&
      DataPurpose.length &&
      BankData.length &&
      AdminData.length
    ) {
      fetchExpense();
    }
  }, [id, data, DataPurpose, BankData, AdminData]);

  const expenseTypes = data.filter(
    (item) => item.type === "Expense Category" && item.parentId === null
  );

  const filteredSubType = data
    .filter((item) => item.parentId === selectedExpenseType)
    .map((subType) => ({ value: subType.id, label: subType.value }));

  const filteredPurpose = DataPurpose.filter(
    (item) => item.type === "Purpose" && item.parentId === null
  );

  const handleSubmitClick = async (event: React.FormEvent) => {
    event.preventDefault();

    if (
      !selectedexpenseMode ||
      !From ||
      !selectedExpenseSubType ||
      !expensePurpose ||
      expenseAmount <= 0 ||
      !expenseAmount ||
      !description
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      if (isEdit) {
        await updateExpenseApi(
          id,
          selectedexpenseMode.value,
          From,
          expensePurpose,
          expenseAmount,
          selectedExpenseSubType,
          description
        );
        toast.success("Expense Updated Successfully");
      } else {
        await addExpenseApi(
          selectedexpenseMode.value,
          From,
          selectedExpenseSubType,
          expensePurpose,
          expenseAmount,
          description
        );
        toast.success("Expense Added Successfully");
      }

      redirect("/admin/Expenses");
    } catch (error) {
      alert("Failed to process expense");
    }

    handleResetForm();
  };

  const handleResetForm = () => {
    setExpenseMode(null);
    setFrom("");
    setSelectedExpenseType(null);
    setSelectedExpenseSubType("");
    setExpensePurpose(null);
    setExpenseAmount(0);
    setDescription("");
    setExpenseOn("");
    navigate(-1);
  };

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  return (
    <div className={`${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <div
        className={`max-w-6xl p-6 ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}
      >
        <div className=" text-center text-3xl font-bold text-blue-600 mb-5 mt-5 justify-center">
          {isEdit ? "Update Expense Details" : "Add New Expense Details"}
        </div>
        <form onSubmit={handleSubmitClick} className="mt-8 space-y-4">
          <div className="flex flex-col space-y-3 ">
            <label className="font-medium text-black dark:text-gray-200 ">
              Expense Mode
            </label>
            <select
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={selectedexpenseMode?.value || ""}
              required
              onChange={(e) => {
                const mode = ExpenseModes.find(
                  (option) => option.value === e.target.value
                );
                setExpenseMode(mode || null);
                setFrom(null);
              }}
            >
              <option value="">Select Expense Mode</option>
              {ExpenseModes.map((mode) => (
                <option key={mode.id} value={mode.value}>
                  {mode.label}
                </option>
              ))}
            </select>

            {selectedexpenseMode && (
              <div>
                <label className="block mb-2 font-medium text-black dark:text-gray-200">
                  Expense From
                </label>

                <select
                  className="w-full p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
                  value={From || ""}
                  required
                  onChange={(e) => setFrom(e.target.value)}
                >
                  {/* Placeholder Option */}
                  <option value="" disabled>
                    {selectedexpenseMode.value === "Bank"
                      ? "Choose Bank - Account Number"
                      : "Select Person"}
                  </option>

                  {/* Dynamic Options */}
                  {(selectedexpenseMode.value === "Bank"
                    ? BankData
                    : AdminData
                  ).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <label className="font-medium text-black dark:text-gray-200">
              Expense Type
            </label>
            <select
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={selectedExpenseType || ""}
              required
              onChange={(e) => {
                setSelectedExpenseType(e.target.value);
                setSelectedExpenseSubType(null);
              }}
            >
              <option value="">Select Expense Type</option>
              {expenseTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.value}
                </option>
              ))}
            </select>

            <label className="font-medium text-black dark:text-gray-200">
              Expense Sub Type
            </label>
            <select
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={selectedExpenseSubType || ""}
              required
              onChange={(e) => setSelectedExpenseSubType(e.target.value)}
            >
              <option value="">Select Sub Type</option>
              {filteredSubType.map((subType) => (
                <option key={subType.value} value={subType.value}>
                  {subType.label}
                </option>
              ))}
            </select>

            <label className="font-medium  text-black dark:text-gray-200">
              Purpose
            </label>
            <select
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={expensePurpose || ""}
              required
              onChange={(e) => setExpensePurpose(e.target.value)}
            >
              <option value="">Select Purpose</option>
              {filteredPurpose.map((purpose) => (
                <option key={purpose.id} value={purpose.id}>
                  {purpose.value}
                </option>
              ))}
            </select>

            <label className="font-medium text-black dark:text-gray-200">
              Expense Amount
            </label>
            <input
              type="number"
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={expenseAmount}
              required
              onChange={(e) => setExpenseAmount(parseFloat(e.target.value))}
            />

            <label className=" font-medium text-black dark:text-gray-200">
              {" "}
              Expense On
            </label>
            <input
              type="date"
              className="p-2 border border-gray-300 rounded leg text-gray-700 focus: outline-none focus focus:ring-blue-400 focus:border-blue-500"
              value={ExpenseOn}
              required
              onChange={(e) => setExpenseOn(e.target.value)}
            />

            <label className="font-medium  text-black dark:text-gray-200">
              Description
            </label>
            <textarea
              className="p-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-500"
              value={description}
              required
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="flex justify-start space-x-4 mt-6">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            >
              {isEdit ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
              onClick={handleResetForm}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

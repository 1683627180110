import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;


const cookies = new Cookies();
export const getBankApi = async () =>{
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(
        `${apiUrl}/bank/get`, {
          headers:{
            Authorization :`Bearer ${token}`,
          },
        });
        return response.data;
      } catch(error) {
        throw error;
      }
    };


export const addBankApi = async(
  name: string, 
  branch: string,
  ifsccode: string,
  accountnumber: string
) => {
  const token = cookies.get('authToken');
  try {
    const response = await axios.post(
      `${apiUrl}/bank/create`, {
         name,
         branch,
         ifsccode,
         accountnumber,
      }, {
        headers :{
          Authorization : `Bearer ${token}`,
        },
      }
    )
    return response.data;
  } catch (error) {
    console.error('API Error', error);
    throw error
  }
}

export const updateBankApi = async(
  Id: string,
  Name: string,
  Branch: string,
  IFSCCode: string,
  AccountNumber: string
) => {
  const token = cookies.get('authToken');
  try {
    const response = await axios.post(
      `${apiUrl}/bank/update`,{
        Id,
        Name,
        Branch,
        IFSCCode,
        AccountNumber,
      }, {
        headers:{
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('API Error', error);
    throw error
  }
}


export const getAllBankApi = async () => {
  const token = cookies.get('authToken');
  try {
    const response = await axios.get(`${apiUrl}/bank/getallbank`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const fetchUsersApi = async () => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/login/getuser`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchTaxonomiessApi = async () => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/taxonomy/gettaxonomy`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const addBoxApi = async (userId: string, taxonomyId: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/box/add`,
      {
        UserId: userId,
        TaxonomyId: taxonomyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchBoxesApi = async () => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/box/get`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchUserByIdApi = async (userId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/login/getuserbyid/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    return false;
  }
};

export const fetchTaxonomyByIdApi = async (taxonomyId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/taxonomy/gettaxonomybyid/${taxonomyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    return false;
  }
};

export const deleteBoxApi = async (taxonomyId: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.request({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/box/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taxonomyId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting box:", error);
    throw error;
  }
};

export const fetchBoxByTaxonomyIdApi = async (taxonomyId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/box/getboxbytaxonomyid/${taxonomyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching box by taxonomyId:", error);
    return false;
  }
};

export const updateboxApi = async (userId: string, taxonomyId: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/box/update`,
      {
        UserId: userId,
        TaxonomyId: taxonomyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

import React, { useContext } from "react";
import Projects from "../../Jsondata/Ourprojects";
import { ThemeContext } from "../theme/ThemeProvider";
import { NavLink } from "react-router-dom";
import Scrolltopbutton from "../scrolltopbutton/Scrolltopbutton";
const HeaderProjects = () => {

    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;



    const cards = Projects.map((article) => (
        <NavLink to={article.mainlink}>
            <div
                key={article.title}
                className={`rounded-md overflow-hidden shadow-md hover:scale-105 transition-transform duration-150 ${isDarkMode ? " bg-[#2e2e2e]" : ""} `}
            >
                <div className="relative flex justify-center items-center">
                    <img
                        src={article.image}
                        alt={article.title}
                        className="w-40 mt-3 h-auto object-contain"
                    />
                    <span className="absolute top-2 right-3 bg-gradient-to-r from-yellow-400 to-red-500 text-white text-xs font-medium py-0.5 px-3 rounded-3xl">
                        {article.Badge}
                    </span>
                </div>
                <div className="p-4">
                    <h3 className={`text-base text-center font-semibold mb-4  ${isDarkMode ? "text-[#afaea9]" : ""}`}>{article.title}</h3>
                    <p className={` text-sm leading-relaxed mt-4 font- text-gray-400 `}>
                        {article.description}
                    </p>
                    <ul className="list-disc pl-5 text-gray-400 font-light text-sm">
                        <li>{article.point1}</li>
                        <li>{article.point2}</li>
                        <li>{article.point3}</li>
                        <li>{article.point4}</li>
                    </ul>
                    <div className="mt-4 flex flex-col justify-between gap-4">
                        <NavLink
                            to={article.mainlink}
                            className="block text-center w-full bg-gray-700 text-white py-2 rounded hover:bg-gray-800"
                        >
                            Show More
                        </NavLink>
                        <NavLink
                            to={article.to}
                            className="block text-center w-full bg-blue-400 text-white py-2 rounded hover:bg-blue-500"
                        >
                            Donate
                        </NavLink>
                    </div>
                </div>
            </div>
        </NavLink>


    ));

    return (
        <div className={` ${isDarkMode ? "bg-globalBg" : "bg-white"}`}>
            <div className={`py-8 `}>
                <h2 className="text-4xl font-bold text-center text-blue-400 mb-8">
                    Our Projects
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 lg:mx-72 md:px-3 px-2 ">
                    {cards}
                </div>
            </div>
            <Scrolltopbutton/>
        </div>
    );
}

export default HeaderProjects;
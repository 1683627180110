import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import {
  fetchBoxesApi,
  fetchUserByIdApi,
  fetchTaxonomyByIdApi,
  deleteBoxApi,
} from "../../Api/Box";
import {
  IconArrowLeft,
  IconArrowRight,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";

const Box = () => {
  const [boxes, setBoxes] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchBoxesData = async () => {
    try {
      const data = await fetchBoxesApi();
      if (data && Array.isArray(data)) {
        const enrichedData = await Promise.all(
          data.map(async (box) => {
            const user = await fetchUserByIdApi(box.userId);
            const taxonomy = await fetchTaxonomyByIdApi(box.boxId);

            return {
              ...box,
              userName: user?.name || "Unknown User",
              taxonomyType: taxonomy?.type || "Unknown Taxonomy",
            };
          })
        );
        setBoxes(enrichedData);
      }
    } catch (error) {
      console.error("Failed to fetch boxes data", error);
    }
  };

  useEffect(() => {
    fetchBoxesData();
  }, []);

  const handleDeleteClick = async (boxId: string) => {
    try {
      await deleteBoxApi(boxId);
      alert("Box deleted successfully");

      fetchBoxesData();
    } catch (error) {
      console.error("Error deleting Box:", error);
    }
  };

  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/admin/box/boxform");
  };

  const handleEditClick = (boxId: string, userId: string) => {
    navigate("/admin/box/boxform", { state: { boxId, userId } });
  };

  const totalPages = Math.ceil(boxes.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentBoxes = boxes.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="container px-4 mx-auto mt-4">
      <div className="w-3/4">
        <h1 className="text-3xl font-bold text-blue-600 mb-5 mt-5">Box</h1>
      </div>
      <div className="flex-grow ml-0 w-full mt-6">
        <input
          type="text"
          placeholder="Search Boxes"
          className="w-full mb-4 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="flex-grow ml-0 w-3/4">
        <form className="w-auto">{/* Search Form */}</form>
      </div>
      <div className="flex-grow ml-0 mt-4 w-full">
        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-6">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  Actions
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <div className="flex items-center gap-x-3">
                    <span>Name</span>
                  </div>
                </th>

                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <button className="flex items-center gap-x-2">
                    <span>Box</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {currentBoxes.length > 0 ? (
                currentBoxes.map((box) => (
                  <tr key={box.boxId}>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="flex items-center gap-x-6">
                        <button onClick={() => handleDeleteClick(box.boxId)}>
                          <IconTrash className="cursor-pointer text-red-500" />
                        </button>
                        <button
                          onClick={() => {
                            handleEditClick(box.userId, box.boxId);
                          }}
                        >
                          <IconPencil className="cursor-pointer text-yellow-500" />
                        </button>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm font-medium text-gray-700">
                      {box.userName}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-500">
                      {box.taxonomyType}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center py-4">
                    No boxes found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex-grow ml-0 mt-4 w-0">
          <button
            type="button"
            onClick={handleAddClick}
            className="flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <FaPlus className="mr-2 font-medium" />
            Box
          </button>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            <IconArrowLeft />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 ${
                currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700 hover:bg-gray-400"
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
            disabled={currentPage === totalPages}
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          >
            <IconArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Box;

import React, { useContext } from "react";
import {
    IconBrandGoogleMaps,
    IconUser,
    IconAccessPoint,
    IconBrandTwitter,
    IconBrandInstagram,
    IconBrandYoutube,
    IconBrandFacebook,
    IconBrandWhatsapp,
} from "@tabler/icons-react";
import { ThemeContext } from "../theme/ThemeProvider";

const mockdata = [
    {
        title: "Our Location",
        description:
            "Syed's Residency, #9-4-137/91/13, Ground Floor, Quli Qutb Shah Nagar, Tolichowki, Hyderabad, India - 500008",
        link1: "https://maps.app.goo.gl/R3bJARUtNNaHHcsi9",
        icon: IconBrandGoogleMaps,
        icon2: IconAccessPoint,
        icon3: IconBrandTwitter,
        icon4: IconBrandInstagram,
        Icon5: IconBrandYoutube,
        Icon6: IconBrandFacebook,
    },
    {
        title: "Contact Us",
        description: "Mobile: +91 8143917806",
        link1: "https://wa.me/+918143917806",
        link2: "mailto:support@tajushshariahfoundation.org",
        icon: IconUser,
        icon2: IconAccessPoint,
        icon3: IconBrandTwitter,
        icon4: IconBrandInstagram,
        Icon5: IconBrandYoutube,
        Icon6: IconBrandFacebook,
    },
    {
        title: "Follow us",
        description: "",
        link3: "https://twitter.com/tajushshariahfo",
        link4: "https://www.instagram.com/tajushshariahfoundationorg",
        link5: "https://www.youtube.com/@TajushShariahFoundationOrg",
        link6: "https://www.facebook.com/TajushShariahFoundationOrg/",
        link7: "https://chat.whatsapp.com/DrM9V5mwDY7C9bzg4YAfK0",
        icon: IconAccessPoint,
        icon2: IconBrandTwitter,
        icon3: IconBrandInstagram,
        icon4: IconBrandYoutube,
        Icon5: IconBrandFacebook,
        Icon6: IconBrandWhatsapp,
    },
];

const Contact = () => {

    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;

    return (
        <div
            className={` min-h-[50vh] py-5  ${isDarkMode ? "bg-globalBg text-white" : "bg-white text-black"
                }`}
        >
            <h1 className="text-3xl font-bold mb-6 text-center text-blue-400 md:mt-4">
                Get In Touch
            </h1>
            <div className="grid grid-cols-1 md:grid-col-3  lg:justify-center  lg:flex  md:flex md:items-center gap-6 md:px-2 px-10">
                {mockdata.map((data) => (
                    <div
                        key={data.title}
                        className={`max-w-xs ${isDarkMode
                            ? "bg-[#2e2e2e] hover:bg-[#a5d8ff]"
                            : " hover:bg-[#A5D8FF] "
                            }  p-4 rounded-[30px] w-[267px] h-56 transition-transform duration-300 ease-in-out  hover:scale-105 `}
                    >
                        <div className=" mb-4 flex">
                            <data.icon
                                className={`w-12 h-12 ${isDarkMode ? "text-blue-400" : "text-blue-500"
                                    }`}
                                stroke={2}
                            />
                        </div>
                        <h3 className="text-[16px] font-semibold mb-7">{data.title}</h3>
                        <a className="text-blue-500 " href={data.link1}>
                            <p className="text-sm leading-relaxed mb-2">{data.description}</p>
                        </a>
                        {data.link2 && (
                            <a className="text-blue-500 " href={data.link2}>
                                <p className="text-sm leading-relaxed">
                                    {data.link2.includes("mailto:")
                                        ? "Email: support@tajushshariahfoundation.org"
                                        : data.link2}
                                </p>
                            </a>
                        )}
                        <div className="flex space-x-4 mt-10">
                            {data.link3 && (
                                <a className="text-blue-500 hover:underline" href={data.link3}>
                                    <data.icon2 className="w-6 h-6" />
                                </a>
                            )}
                            {data.link4 && (
                                <a className="text-blue-500 hover:underline" href={data.link4}>
                                    <data.icon3 className="w-6 h-6" />
                                </a>
                            )}
                            {data.link5 && (
                                <a className="text-blue-500 hover:underline" href={data.link5}>
                                    <data.icon4 className="w-6 h-6" />
                                </a>
                            )}
                            {data.link6 && (
                                <a className="text-blue-500 hover:underline" href={data.link6}>
                                    <data.Icon5 className="w-6 h-6" />
                                </a>
                            )}
                            {data.link7 && (
                                <a
                                    className="text-blue-500 hover:underline"
                                    href={data.link7}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <data.Icon6 className="w-6 h-6" />
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Contact;

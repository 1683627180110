import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

const cookies = new Cookies();

export const getAllBalanceTransfer = async (banktransferId: string)=>{
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(`${apiUrl}/balancetransfer/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          banktransferId,
        },
        
      });
      return response.data;
    } catch (error) {
      console.error(error)
      throw error;
    }
  }
  
  export async function addBalanceTransfer(
    transferaccountFrom: string,
    transferFrom: string,
    transferTo: string,
    transferaccountTo: string,
    Amount: number,
    desc: string
  ) {
    const token = cookies.get('authToken');
    try {
      const response = await axios.post(
        `${apiUrl}/balancetransfer/create`,
  
        {
          transferaccountFrom,
          transferFrom,
          transferTo,
          transferaccountTo,
          Amount,
          desc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
  
  export async function updateBalanceTransfer(
    Id: string,
    transferaccountFrom: string,
    transferFrom: string,
    transferTo: string,
    transferaccountTo: string,
    Amount: number,
    desc: string
  ) {
    const token = cookies.get('authToken');
    try {
      const response = await axios.post(
        `${apiUrl}/balancetransfer/update`,
        {
          Id,
          transferaccountFrom,
          transferFrom,
          transferTo,
          transferaccountTo,
          Amount,
          desc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
  
  export const getBalanceTransferById = async (id: string) => {
    const token = cookies.get('authToken');
    try {
      const response = await axios.get(`${apiUrl}/balancetransfer/getbyid`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
  
      return false;
    }
  };